/*
    Type
 */
html {
    color: $color-dark-tint-1;
    font-family: $font-primary-light;
    font-size: 16px;
    font-weight: $weight-light;
    line-height: 1.44;
    word-break: break-word;
    word-wrap: break-word;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}


// Normal text
p {
    margin-top: 0;
    margin-bottom: rem(20);
}

b, strong {
    font-family: $font-primary;
    font-weight: $weight-bold;
}

em {
    font-style: italic;
}

address {
}

small {
}


// Link
a {
    color: inherit;
    text-decoration: none;
}

p,
li {
    @media (--from-tiny-screen-w) {
        font-size: rem(18);
    }

    a {
        &:not(.button) {
            display: inline-block;

            border-bottom: 1px solid $color-primary;

            transition:
                color 0.18s ease-in-out,
                border-color 0.18s ease-in-out;

            &:hover {
                color: $color-primary;

                border-color: transparent;
            }
        }
    }
}
