/*
    Filter Results Page
 */
.filter-results-page {
    min-height: rem(300);
    padding-top: rem(30);

    @media (--from-medium-screen-w) {
        padding-top: rem(40);
    }

    @media (--from-normal-screen-w) {
        padding-top: rem(85);
    }

    // Elements
    &-title {
        position: relative;

        text-align: center;

        .loader {
            position: absolute;
            top: 0;
            left: 0;
        }

        // Hide when loader is inset
        &-text:not(:only-child) {
            opacity: 0;
        }
    }
}
