/*
    Page Header
 */
.page-header {

    // States
    &.homepage {

        // Elements
        .page-header-title {
            padding-top: rem(13);
            padding-right: rem(10);
            padding-bottom: rem(13);
            padding-left: rem(20);
            margin-top: rem(-70);
            margin-bottom: rem(20);
            margin-left: rem(-20);

            color: $color-light;
            font-size: rem(36);

            background-color: $color-dark;

            @media (--from-medium-screen-w) {
                flex-basis: 50%;

                padding-top: rem(25);
                padding-right: rem(35);
                padding-bottom: rem(25);
                padding-left: rem(30);
                margin-top: rem(-60);
                margin-bottom: rem(10);
                margin-left: rem(-10);

                font-size: rem(48);
            }

            @media (--from-normal-screen-w) {
                padding-left: rem(55);
                margin-bottom: 0;
                margin-left: rem(-20);

                font-size: rem(65);
            }
        }

        .page-header-inner {
            @media (--from-large-screen-w) {
                padding-right: 0;
            }
        }

        .page-header-card {
            @media (--from-medium-screen-w) {
                padding-bottom: rem(20);
            }
        }

        .page-header-card-background-shadow {
            display: none;
        }

        .page-header-card-background-box {
            @media (--from-medium-screen-w) {
                top: 0;
                right: 0;
                bottom: 0;
                left: rem(60);

                width: auto;
            }

            @media (--from-normal-screen-w) {
                left: rem(50);
            }
        }

        .page-header-card-content {
            @media (--from-medium-screen-w) {
                display: flex;
                align-items: flex-start;

                padding-left: rem(20);
            }
        }

        .page-header-extra {
            @media (--from-medium-screen-w) {
                display: block;
                flex-basis: 50%;
                padding-left: rem(55);
            }

            @media (--from-normal-screen-w) {
                padding-right: rem(50);
                padding-left: rem(100);
            }
        }

        .page-header-intro {
            @media (--from-medium-screen-w) {
                margin-bottom: rem(20);
            }
        }

        .page-header-button {
            @media (--from-medium-screen-w) {
                text-align: left;
            }
        }

    }


    // Elements
    &-inner {
        position: relative;

        @media (--from-medium-screen-w) {
            padding-right: rem(40);
            margin-top: rem(-40);
        }

        @media (--from-normal-screen-w) {
            margin-top: rem(-85);
        }
    }

    &-card {
        position: relative;

        padding-top: rem(28);
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);

        @media (--from-medium-screen-w) {
            padding-bottom: rem(35);
        }
    }

    &-card-background-shadow {
        position: absolute;
        top: rem(40);
        right: rem(-20);
        bottom: rem(-20);

        width: rem(156);

        background: $color-dark;
        content: '';

        @media (--to-medium-screen-w) {
            display: none;
        }
    }

    &-card-background-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        width: rem(1232);

        background: $color-light;
        box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.24);
    }


    &-card-content {
        position: relative;
    }

    &-title {
        margin-bottom: rem(13);

        font-family: $font-secondary;
        font-size: rem(36);
        font-weight: $weight-regular;
        text-indent: -0.04em; // Adjust Brandon font-alignment

        @media (--from-medium-screen-w) {
            font-size: rem(48);
        }
    }

    &-extra {
        @media (--from-medium-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-intro-block {
        flex-basis: 60%;
    }

    &-author {
        margin-bottom: rem(5);

        font-weight: $weight-bold;

        @media (--from-medium-screen-w) {
            margin-bottom: rem(7);
        }
    }

    &-intro {
        margin-bottom: rem(20);

        @media (--from-medium-screen-w) {
            margin-bottom: 0;
        }
    }

    &-button {
        flex-grow: 1;

        @media (--from-medium-screen-w) {
            text-align: center;
        }


        // Elements
        a.button {
            height: rem(50);
            padding-right: rem(30);
            padding-left: rem(30);

            @media (--from-medium-screen-w) {
                font-size: rem(18);
            }
        }
    }

    &-media {
        position: relative;

        height: rem(220);
        overflow: hidden;

        background: $color-dark;

        @media (--from-medium-screen-w) {
            height: rem(325);
        }

        @media (--from-normal-screen-w) {
            height: rem(520);
        }
    }
}
