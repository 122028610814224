/*
    Navigation
 */
$button-size: 40;
$button-size-medium: 50;
$button-size-large: 60;

.navigation {
    @media (--to-nav-w) {
        border-top: rem(2) solid $color-dark-tint-1;
    }

    @media (--from-nav-w) {
        display: flex;
        align-items: center;
    }


    // Elements
    &-item {
        position: relative;

        @media (--to-nav-w) {
            border-bottom: rem(2) solid $color-dark-tint-1;
        }


        // States
        &:hover,
        &.is-active {
            .sub-navigation {
                @media (--from-nav-w) {
                    height: auto;
                    overflow: visible;

                    opacity: 1;

                    transform: translate(-50%, 0);
                    transition:
                        transform 0.2s ease-in-out,
                        opacity 0.2s ease-in-out;
                }
            }
        }

        &:hover .sub-navigation-toggle .icon {
            @media (--from-nav-w) {
                transform: rotate(-90deg);
            }
        }

        &.is-active {
            .sub-navigation {
                @media (--to-nav-w) {
                    display: block;
                }
            }

            .sub-navigation-toggle .icon {
                transform: rotate(-90deg);
            }
        }


        // Types
        &:not(.button) {
            padding-right: rem(10);

            @media (--from-nav-w) {
                display: flex;
                align-items: center;
                margin-right: rem(0);
                margin-left: rem(10);
            }

            @media (--from-normal-screen-w) {
                margin-right: rem(10);
                margin-left: rem(20);
            }
        }

        &.button {

            cursor: pointer;

            &:last-child {
                @media (--to-nav-w) {
                    margin-top: rem(20);
                }

                @media (--from-nav-w) {
                    position: absolute;
                    right: 0;
                }
            }
        }

    }

    &-button-wrapper {
        padding-right: rem(10);
        padding-left: rem(10);
        margin-bottom: rem(10);

        text-align: center;
    }

    &-link {
        display: flex;
        align-items: center;
        height: rem($button-size);

        line-height: 1;
        letter-spacing: 0.02em; // Increase light on dark

        @media (--to-nav-w) {
            width: 100%;
            height: rem($button-size-large);

            font-size: rem(18);
        }

        @media (--from-nav-w) {
            flex-shrink: 0;
            height: rem($button-size-medium);
            padding-right: rem(10);
            padding-left: rem(10);

            font-weight: $weight-semi-bold;
        }

        &:hover {
            @media (--from-nav-w) {
                color: $color-primary;
            }
        }

        &.is-active {
            color: $color-primary;
        }
    }


    // Elements
    .sub-navigation-toggle {
        width: rem(16);
        height: rem(30);

        color: inherit;
        text-align: right;

        @media (--to-nav-w) {
            position: absolute;
            top: 0;
            right: 0;

            justify-content: flex-end;
            width: rem($button-size-large);
            height: rem($button-size-large);
        }

        .icon {
            display: inline-block;
            width: rem(16);
            height: rem(16);

            transform: rotate(90deg); // Makes use of chevron-right icon
            transition: transform 0.2s ease-in-out;
        }
    }
}
