// !important is used to overwrite the component's css.
.field.is-invalid .DayPickerInput input {
    box-shadow: 0 0 0 2px $color-danger;
}

.DayPickerInput {
    z-index: 3;

    width: 100%;
}

.DayPicker-Day {
    padding: rem(5) rem(8) !important;

    border-radius: 50% !important;
}

.DayPicker-Day--today {
    color: $color-dark !important;
}

.DayPicker-Day--selected {
    color: $color-light !important;
}

.DayPicker-Day--disabled {
    color: $color-light-shade-2 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $color-primary !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $color-primary !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $color-light !important;

    background-color: $color-primary !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent !important;
}

