/*
    Block Section
 */
.block-section {
    padding-top: rem($section-spacing);
    padding-bottom: rem($section-spacing);
    overflow: hidden;

    background-color: $color-light-shade-1;

    @media (--from-medium-screen-w) {
        padding-top: rem($section-spacing-large);
        padding-bottom: rem($section-spacing-large);
    }

    &.dark {
        background-color: $color-dark;
    }

    &.light {
        background-color: $color-light;
    }
}


// Relationships
.block-section + .block-section {
    margin-top: rem(-60);

    @media (--from-medium-screen-w) {
        margin-top: rem(-80);
    }
}