/*
    Footer
 */
.footer {
    .link {
        font-size: rem(14);
    }

    .footer-sponsors {
        @media (--to-small-screen-w) {
            display: none;
        }
    }
}
