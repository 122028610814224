/*
    Menu Button
 */
.action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: color 0.3s;

    @media (--to-small-screen-w) {
        width: auto;
    }


    // States
    &:hover {
        color: $color-primary;
    }


    // Elements
    &-label {
        margin-bottom: 0;

        line-height: 1;

        &:last-child {
            padding-left: rem(10);

            @media (--from-medium-screen-w) {
                padding-left: rem(15);
            }
        }

        &:first-child {
            padding-right: rem(10);

            @media (--from-medium-screen-w) {
                padding-right: rem(15);
            }
        }
    }

    .icon {
        width: rem(18);
        height: rem(18);

        @media (--from-small-screen-w) {
            width: rem(26);
            height: rem(26);
        }
    }
}
