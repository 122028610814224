/*
    Tile Blocks
 */
.tile-block {

    // Elements
    &-inner {
        margin-right: rem(-$grid-spacing);
        margin-bottom: calc(rem(-$grid-spacing) * 2);
        margin-left: rem(-$grid-spacing);

        @media (--from-small-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }

        @media (--from-normal-screen-w) {
            margin-right: rem(-$grid-spacing-large);
            margin-bottom: calc(rem(-$grid-spacing-large) * 2);
            margin-left: rem(-$grid-spacing-large);
        }
    }

    .tile {
        padding-right: rem($grid-spacing);
        padding-left: rem($grid-spacing);
        margin-bottom: calc(rem($grid-spacing) * 4);

        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2); // IE flex-wrap
        }

        @media (--from-medium-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
            margin-bottom: calc(rem($grid-spacing-large) * 2);
        }

        @media (--from-normal-screen-w) {
            padding-right: rem($grid-spacing-large);
            padding-left: rem($grid-spacing-large);
        }
    }
}
