/*
    Button
 */
a.button {
    line-height: 1;

    text-decoration: none;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(40);
    min-width: rem($button-width);
    padding-top: rem(2);
    padding-right: rem(20);
    padding-left: rem(20);

    color: $color-light;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: $weight-regular;
    line-height: 1;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;

    background-color: $color-dark-tint-1;
    border: 2px solid transparent;
    border-radius: rem(50);

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;


    // States
    &:hover {
        background-color: $color-dark-tint-2;
    }

    &:active,
    &.is-active {
        background-color: $color-dark;
    }

    &:disabled,
    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;

        opacity: 0.3;
    }


    // Types
    &.block {
        position: relative;

        display: block;
        width: 100%;
        padding-right: rem(50);

        font-family: $font-primary;
        font-weight: $weight-regular;
        text-align: left;
        text-transform: none;

        border: none;
        border-radius: 0;


        // Elements
        .icon {
            position: absolute;
            top: 50%;
            right: rem(15);

            width: rem(28);
            height: rem(28);

            transform: translateY(-50%);
        }
    }


    // Sizes
    &.small {
        height: rem(30);
        min-width: rem(80);
        padding-right: rem(12);
        padding-left: rem(12);

        font-size: rem(14);
        letter-spacing: 0.06em;

        .button-icon {
            width: rem(20);
            height: rem(20);
        }
    }

    &.large {
        height: rem(60);
        min-width: rem(280);
        padding-right: rem(30);
        padding-left: rem(30);

        font-size: rem(20);

        .button-icon {
            width: rem(40);
            height: rem(40);
        }
    }


    // Themes
    &.primary {
        background-color: $color-primary;
        border-color: transparent;


        // States
        &:hover {
            background-color: $color-primary-tint-1;
        }

        &:active,
        &.is-active {
            background-color: $color-primary-shade-1;
        }
    }

    &.alternative {
        color: $color-dark;

        background-color: transparent;
        border-color: $color-primary;


        // States
        &:hover {
            background-color: $color-primary;
        }

        &:active,
        &.is-active {
            background-color: color($color-primary alpha(30%));
        }

        // Types
        &:not(.inverse) {
            font-weight: $weight-bold;
        }

        &.inverse {
            color: $color-light;
        }
    }
}

// Broken to avoid creating mulitple classes
.button-icon {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    width: rem(30);
    height: rem(30);


    // Types
    &.left {
        margin-right: rem(10);
    }

    &.right {
        margin-left: rem(10);
    }
}
