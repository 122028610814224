/*
 Input
*/
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='tel'],
input[type='date'],
textarea,
.DayPickerInput input {
    width: 100%;
    height: rem(38);
    max-width: 100%;
    padding-right: rem(8);
    padding-left: rem(8);

    color: $color-dark;
    font-family: inherit;
    font-size: rem(18);

    background-color: transparent;
    border: none;
    border-radius: rem(2);
    box-shadow: 0 0 0 2px $color-light-shade-2;

    appearance: none;


    // States
    &:focus {

    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;

        opacity: 0.2;
    }

    &.is-invalid {
        box-shadow: 0 0 0 2px $color-danger;
    }


    // Elements
    &::placeholder {
        color: $color-dark-tint-3;
    }
}

textarea {
    height: rem(160);
    max-height: rem(300);
    padding-top: rem(7);
    padding-bottom: rem(7);
}

select {
    height: rem(40);
}
