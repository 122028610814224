/*
    Stackla

    Description:
    This core overrides for the Stackla classes.
 */
.stacklapopup-wrap {

    // Elements
    .stacklapopup-content-wrap,
    .stacklapopup-caption {
        color: $color-dark;
        font-family: $font-primary-light;
    }

    .stacklapopup-user-name,
    .stacklapopup-user-handle {
        color: $color-dark;
    }

    .stacklapopup-interaction-link {
        text-decoration: none;
    }

    .stacklapopup-arrow {
        color: $color-light;

        background-color: $color-primary;

        opacity: 1;
    }
}

.stackla-widget-mask {
    position: fixed !important; // Override inline style to prevent huge overflow in the body
}
