/*
    Page
 */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    position: relative;

    min-width: 320px;
    min-height: 100%;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;

    // Hide the recaptcha badge
    .grecaptcha-badge {
        visibility: hidden;
    }


    // Types
    &.is-fixed {
        overflow: hidden;
    }

    &.hide-fixed-items {
        .header {
            opacity: 0;
        }
    }
}