/*
    Footer Information
 */
.footer-information {
    padding-bottom: rem(20);

    &-inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: rem(30);

        @media (--from-medium-screen-w) {
            flex-direction: row;
            justify-content: space-between;
        }
    }


    // Elements
    &-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @media (--to-small-screen-w) {
            flex-basis: 100%;
        }

        // Elements
        .icon {
            width: rem(35);

            color: $color-primary;
        }
    }

    &-social-media {
        max-width: rem(200);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (--from-small-screen-w) {
            max-width: 100%;
        }


        p {
            margin-right: rem(4);
            margin-left: rem(4);
        }
    }

    &-enquiry:first-child {
        margin-right: rem(0);
    }

    &-enquiry {
        align-items: center;
        justify-content: center;

        @media (--from-small-screen-w) {
            display: inline-flex;
        }
    }

    .copyrights {
        text-align: center;

        @media (--from-medium-screen-w) {
            flex-basis: rem(475);
        }
    }

    .logo {
        width: rem(220);

        &.rfa {
            padding: 0 rem(20);

            @media (--from-medium-screen-w) {
                padding: 0 0 0 rem(40);
            }
        }
        
        .image {
            margin: auto;

            @media (--from-medium-screen-w) {
                margin-left: 0;
            }
        }
    }

    .logo,
    .copyrights {
        margin-bottom: rem(25);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(10);
        }
    }

    p {
        margin: rem(8);

        &:not([class]) {
            font-size: rem(14)
        }
    }

    .policy-links {
        .link {
            display: inline-block;
            margin: 0 5px;
        }
    }
}
