/*
    Tile
 */
.tile {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;


    // Elements
    &-link-wrap {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &-inner {
        position: relative;

        display: flex;
        flex-direction: column;
        flex-grow: 1;

        overflow: hidden;

        color: $color-light;
        text-decoration: none;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 rem(5) rem(30) color($color-dark alpha(20%));

        @media (--to-large-screen-w) {
            background-color: $color-light;
            background-image: none !important; // Override inline style declaration
        }

        @media (--from-large-screen-w) {
            justify-content: flex-end;
            height: rem(400);
            padding-right: rem(16);
            padding-left: rem(16);

            background-color: $color-dark-tint-1;
        }


        // States
        &:hover,
        &:focus {
            cursor: pointer;


            // Elements
            .tile-subtitle {
                max-height: 100%;
                margin-top: rem(-15);
                margin-bottom: rem(30);

                opacity: 1;
            }

            .tile-title {
                color: $color-primary;

                transition: color 0.15s ease-in-out 0.05s;

                &::before {
                    top: 0;
                }
            }

            .tile-icon {
                color: $color-dark;

                transition: color 0.15s ease-in-out;
            }
        }


        // Elements
        &::before {
            @media (--to-large-screen-w) {
                display: none;
            }
        }
    }

    .tile-title {
        position: relative;

        padding-top: rem(19);
        padding-right: rem(16);
        padding-bottom: rem(15);
        padding-left: rem(16);
        margin-bottom: rem(4);

        transition: color 0.2s ease-in-out;

        @media (--to-large-screen-w) {
            color: $color-primary;
        }

        &::before {
            @media (--from-large-screen-w) {
                position: absolute;
                top: 100%;
                left: 0;

                width: 100%;
                height: rem(500);

                background-color: $color-light;
                box-shadow: 0 rem(10) rem(30) color($color-dark alpha(10%));
                content: '';

                transition: top ease 200ms, color ease 200ms;
            }
        }


        // Elements
        &-inner {
            position: relative;
        }

        &-description {
            margin-bottom: rem(10);
        }
    }

    &-subtitle {
        position: relative;

        max-height: 0;
        padding-right: rem(16);
        padding-left: rem(16);
        margin-top: 0;
        margin-bottom: rem(0);

        color: $color-dark;
        font-family: $font-primary;
        font-size: rem(16);
        font-weight: $weight-semi-bold;

        opacity: 0;

        transition: max-height ease 150ms, opacity ease 200ms, margin-bottom ease 250ms, margin-top ease 250ms;

        @media (--to-large-screen-w) {
            max-height: 100%;
            margin-top: rem(-15);
            margin-bottom: rem(30);

            opacity: 1;
        }
    }

    &-thumbnail {
        background-color: $color-dark;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--from-large-screen-w) {
            display: none; // Hide for bigger resolutions as using the .tile-inner background instead
        }

        &::before {
            display: block;
            max-height: rem(240);
            min-height: rem(220);

            // Cheat for making responsive ratios
            padding-bottom: calc(100% * 3 / 4);

            content: '';

            @media (--from-small-screen-w) {
                max-height: rem(280);
            }
        }
    }

    &-icon {
        position: relative;
        left: rem(-5);

        display: flex;
        align-items: center;

        color: $color-dark;

        transition: color 0.2s ease-in-out;

        svg {
            width: rem(30);
            height: rem(30);
        }

        @media (--from-large-screen-w) {
            color: $color-light;
        }
    }

    &-badge {
        position: absolute;

        width: rem(100);
        height: rem(36);

        color: $color-light;

        background-color: $color-feature;
        box-shadow: 0 rem(6) rem(18) 0 rgba(0, 0, 0, 0.24);
    }

    .image-overlay::before {
        height: 60%;
    }

    &-custom-button {
        margin-top: rem(7);
    }
}
