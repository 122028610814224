/*
    Room Filter
 */
.rooms-filter {

    @media(--from-medium-screen-w) {
        display: flex;
        justify-content: space-between;
    }


    // Elements
    &-fields {
        display: flex;
        justify-content: flex-end;
        padding-top: rem(15);
        padding-bottom: rem(20);

        @media(--from-medium-screen-w) {
            padding-bottom: rem(35);
        }
    }

    &-label {
        margin-bottom: 0;

        font-family: $font-primary;
        font-size: rem(18);
        font-weight: $weight-semi-bold;
        line-height: rem(38);
        white-space: nowrap;
    }

    &-empty {
        position: relative;

        height: rem(420);

        @media(--from-small-screen-w) and (--to-large-screen-w) {
            height: rem(340);
        }

        p {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 100%;

            text-align: center;

            transform: translate(-50%, -50%);
        }
    }

    .select-wrapper {
        width: 100%;
        height: rem(48);
        min-width: rem(200);
        margin-bottom: rem(30);
        margin-left: rem(18);

        @media(--from-medium-screen-w) {
            height: rem(38);
            margin-bottom: 0;
        }


        // Elements
        select {
            font-size: rem(18);
        }
    }
}
