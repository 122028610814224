/*
    Animation
 */

// Spin
@keyframes animation-spin {
    0% {
        transform: rotate(0deg);
    },
    100% {
        transform: rotate(360deg);
    }
}

.animate-spin {
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-name: animation-spin;
    animation-timing-function: linear;
}

// Float
@keyframes animation-float {
    0% {
        transform: translateY(0);
    },
    50% {
        transform: translateY(rem(10));
    },
    100% {
        transform: translateY(0);
    }
}

.animate-float {
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: animation-float;
    animation-timing-function: ease-in-out;
}
