/*
   Room Capacities Block
*/
.room-capacities-block {

    // Elements
    &-inner {
        @media (--to-small-screen-w) {
            flex-direction: column;
            margin: 0 rem(-20);
        }

        @media (--to-normal-screen-w) {
            display: flex;
        }
    }

    &-aside {
        margin-bottom: rem(20);
    }

    .disclaimer {
        margin: 0 rem(20) rem(20) 0;
        max-width: rem(400);
        text-align: left;
    }

    &-list {
        margin-right: 0;

        background-color: $color-light-shade-1;

        @media (--from-small-screen-w) {
            display: inline-flex;
            justify-content: space-between;
            height: rem(120);
            max-width: 100%;
            margin-right: rem(20);
            margin-bottom: rem(30);

            background-color: $color-light;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
        }

        @media (--from-normal-screen-w) and (--to-large-screen-w) {
            max-width: rem(340);
        }
    }

    &-list-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        @media (--from-small-screen-w) {
            flex-direction: column;
            justify-content: center;
            width: rem(94);
        }


        // Elements
        .title {
            display: flex;
            align-items: center;
            padding: rem(12) 0;

            font-family: $font-secondary;
            font-size: rem(12);
            font-weight: $weight-bold;

            @media (--to-small-screen-w) {
                flex-basis: 50%;
                padding-left: rem(50);
            }

            @media (--from-small-screen-w) {
                flex-direction: column;
            }
        }

        .label {
            width: 100%;
            padding: rem(8) 0;

            font-size: rem(14);
            font-weight: $weight-bold;
            text-align: right;

            @media (--to-small-screen-w) {
                flex-basis: 50%;
                padding-right: rem(50);
            }

            @media (--from-small-screen-w) {
                text-align: center;

                border-top: 1px solid $color-light-shade-2;
            }

        }

        .icon {
            width: rem(34);
            height: rem(34);

            @media (--to-small-screen-w) {
                margin-right: rem(8);
            }
        }
    }

    &-title {
        margin-left: rem(25);

        color: $color-dark-tint-1;
        font-size: rem(18);
        font-weight: $weight-bold;

        @media (--from-small-screen-w) {
            margin-left: rem(5);
        }


        // Elements
        &:before {
            position: relative;
            left: rem(-5);

            content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDEwIDE1Ij4gIDxwYXRoIGZpbGw9InJnYigyMDIsIDIwMiwgMjAyKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNSwwIEwxMCw3LjUgTDAsNy41IEw1LDAgWiBNNSwxNSBMMCw3LjUgTDEwLDcuNSBMNSwxNSBaIi8+PC9zdmc+');
        }
    }

    &-layouts {
        @media (--to-small-screen-w) {
            order: 1;
        }
    }

    &-dimensions {
        @media (--to-small-screen-w) {
            margin-bottom: rem(40);
        }
    }
}
