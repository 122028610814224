/*
    Room Listing for a Venue
 */
.filter-results-venue {
    margin-bottom: rem(70);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(80);
    }

    @media (--from-normal-screen-w) {
        margin-bottom: rem(100);
    }


    // Elements
    &-header {
        margin-bottom: rem(30);

        @media (--to-small-screen-w) {
            text-align: center;
        }

        @media (--from-small-screen-w) {
            display: flex;
            align-items: baseline;
        }

        @media (--from-medium-screen-w) {
            margin-bottom: rem(40);
        }

        @media (--from-normal-screen-w) {
            margin-bottom: rem(45);
        }

        // Elements
        &-title {
            flex-grow: 1;

            color: $color-primary;
        }
    }
}
