/*
    Lightbox
 */
.lightbox {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $layer-lightbox;

    width: 100%;
    height: 0;
    overflow: hidden;

    background-color: color($color-dark alpha(85%));
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;


    // States
    &.is-active {
        bottom: 0;

        height: 100%;
    }


    // Elements
    &-button {
        position: absolute;

        background-color: color($color-dark alpha(25%));
        border: 0;

        @media (--from-medium-screen-w) {
            top: rem(40);
            right: rem(40);
        }

        
        // States
        &:hover {
            background-color: $color-dark;
        }

        &.is-disabled {
            opacity: 0.35;
        }


        // Types
        &.close {
            top: rem(20);
            right: rem(20);
        }

        &.previous {
            top: 50%;
            left: rem(20);

            transform: translateY(-50%) rotate(180deg);
        }

        &.next {
            top: 50%;
            right: rem(20);

            transform: translateY(-50%);
        }


        // Elements
        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}
