/*
    Headroom
 */
.headroom {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $layer-header;

    transition: transform 200ms ease-in-out;


    // States
    &--unfixed,
    &--pinned {
        transform: translateY(0);
    }

    &--unpinned {
        transform: translateY(-100%);
    }

    &-wrapper.is-unfixed & {
        position: relative;
    }
}