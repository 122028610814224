/*
    Call To Action
 */
.call-to-action {
    position: relative;
    background-color: $color-dark;


    // Elements
    &-inner {
        min-height: rem(500);

        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
        }
    }

    &-content {
        @media(--to-medium-screen-w) {
            text-align: center;
        }

        @media (--from-medium-screen-w) {
            order: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 50%;
        }

        .inner {
            padding: rem(30);

            @media (--from-medium-screen-w) {
                max-width: rem($constrain-width-small);
                padding: rem(20) rem(60);
            }
        }


        // Elements
        h2 {
            font-family: $font-secondary;
            font-size: rem(32);

            @media (--from-medium-screen-w) {
                font-size: rem(36);
            }

            @media (--from-large-screen-w) {
                font-size: rem(42);
            }
        }
    }

    &-media {
        @media (--from-medium-screen-w) {
            width: 50%;
        }
    }
}
