.search-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 5vh;
    padding-bottom: 12vh;

    @media(--from-medium-screen-w) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: rem(15);
        padding-bottom: rem(15);
    }


    // Elements
    &-label {
        margin-bottom: rem(15);

        font-family: $font-primary;
        font-size: rem(18);
        font-weight: $weight-semi-bold;
        white-space: nowrap;

        @media(--from-medium-screen-w) {
            margin-bottom: 0;

            line-height: rem(38);
        }
    }

    .select-wrapper {
        width: 64%;
        height: rem(48);
        min-width: rem(200);
        margin-right: rem(18);
        margin-bottom: rem(30);
        margin-left: rem(18);

        @media(--from-medium-screen-w) {
            width: rem(200);
            height: rem(38);
            margin-bottom: 0;
        }

        // Elements
        select {
            font-size: rem(18);
        }
    }

    button[type='submit'].find-button {
        display: inline;
        width: rem(90);
        min-width: rem(90);

        @media(--to-medium-screen-w) {
            width: 64%;
            height: rem(50);
            min-width: rem(200);
            margin-top: rem(20);
        }

        @media(--from-medium-screen-w) {
            background-color: $color-primary;
            border-color: transparent;


            // States
            &:hover {
                background-color: $color-primary-tint-1;
            }

            &:active,
            &.is-active {
                background-color: $color-primary-shade-1;
            }
        }
    }
}
