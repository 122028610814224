/*
    Section Header
 */
.section-header {

    // Types
    &.center .section-header-inner {
        margin-right: auto;
        margin-left: auto;

        text-align: center;

        &::after {
            margin-left: auto;
        }
    }

    &.pad {
        padding-top: rem(50);

        @media (--from-medium-screen-w) {
            padding-top: rem(80);
        }
    }


    // Elements
    &-title {
        margin-bottom: rem(10);
    }

    &-subtitle {
        margin-bottom: rem(15);
    }

    &-inner {
        max-width: rem($constrain-width-medium);

        &::after {
            display: block;
            width: rem($hr-width);
            height: rem(2);
            margin-right: auto;
            margin-bottom: rem(40);

            background-color: $color-primary;

            content: '';
        }
    }
}
