/*
    Venues Listing
 */
.filter-results-venues {
    margin-top: rem(40);

    @media (--from-medium-screen-w) {
        margin-top: rem(60);
    }

    @media (--from-normal-screen-w) {
        margin-top: rem(85);
    }
}
