/*
    Text Header
 */
.text-header {
    padding-top: rem($section-spacing);
    padding-bottom: rem($section-spacing);

    text-align: center;

    @media (--from-small-screen-w) {
        padding-top: rem($section-spacing-large);
        padding-bottom: rem($section-spacing-large);
    }

    @media (--from-normal-screen-w) {
        min-height: rem(400);
    }

    .logo {
        display: inline-block;
        margin-bottom: rem(40);
    }
}
