/*
    Grid on the /capacities page.
 */
.capacities-grid {
    // Elements
    &-heading-wrapper {
        display: none; // The heading is hidden on mobile

        @media(--from-medium-screen-w) {
            position: sticky;
            top: 0;
            z-index: $layer-capacities-heading;

            display: block;
            padding-right: rem(20);
            padding-left: rem(20);

            background-color: $color-light;
        }
    }

    &-heading {
        // Elements
        .capacities-grid-text-cell-inner {
            color: $color-dark-tint-1;
            font-size: rem(16);
        }

        .capacities-grid-cell-inner {
            padding-top: rem(10);
            padding-right: 0;
            padding-bottom: rem(25);
            padding-left: 0;

            font-family: $font-brandon;
            font-size: rem(14);
            font-weight: $weight-semi-bold;
            text-align: center;


            // Elements
            svg {
                display: block;
                width: rem(34);
                height: rem(34);
                margin-right: auto;
                margin-bottom: rem(5);
                margin-left: auto;
            }
        }
    }

    &-venues-wrapper {
        @media(--from-medium-screen-w) {
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);
        }
    }

    &-row {
        @media(--from-medium-screen-w) {
            display: flex;
        }
    }

    &-text-cell {
        @media(--from-medium-screen-w) {
            flex-grow: 1;
        }
    }

    &-text-cell-inner {
        min-height: rem(80);
        padding-top: rem(25);
        padding-right: rem(30);
        padding-bottom: rem(10);
        padding-left: rem(30);

        color: $color-primary;
        font-size: rem(16);
        text-align: center;

        @media(--from-medium-screen-w) {
            min-height: rem(90);
            padding-top: rem(35);
            padding-right: rem(10);

            font-size: rem(14);
            text-align: left;
        }


        // Elements
        a {
            position: relative;

            display: inline-block;
            padding-right: rem(30);
            padding-left: rem(30);


            @media(--from-medium-screen-w) {
                display: block;
                padding-left: rem(0);
            }


            // Elements
            svg {
                position: absolute;
                top: rem(-2);
                right: 0;

                width: rem(30);
                height: rem(30);

                @media(--from-medium-screen-w) {
                    top: rem(-4);
                }
            }
        }


    }

    &-cell {
        @media(--to-medium-screen-w) {
            display: flex;
        }

        @media(--from-medium-screen-w) {
            flex-basis: rem(80);
            flex-grow: 0;
            flex-shrink: 0;
        }

        @media(--from-normal-screen-w) {
            flex-basis: rem(110);
        }
    }

    &-cell-label {
        flex-grow: 1;

        @media(--from-medium-screen-w) {
            display: none; // Not showing on desktop
        }
    }

    &-cell-label-inner {
        padding-top: rem(12);
        padding-left: rem(55);

        font-family: $font-brandon;
        font-size: rem(14);
        font-weight: $weight-semi-bold;


        // Elements
        svg {
            width: rem(30);
            height: rem(30);
            margin-right: rem(14);

            vertical-align: rem(-10);
        }
    }

    &-cell-value-inner {
        padding-top: rem(17);
        padding-right: rem(50);
        padding-bottom: rem(10);
        padding-left: rem(10);

        font-family: $font-primary;
        font-size: rem(14);
        font-weight: $weight-semi-bold;
        text-align: right;

        @media(--from-medium-screen-w) {
            padding-top: rem(35);
            padding-right: rem(10);

            text-align: center;
        }
    }

    &-venue-and-rooms {
        position: relative;

        margin-bottom: rem(40);

        background-color: $color-light;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);


        // States
        &.is-active {
            @media(--to-medium-screen-w) {
                background-color: transparent;
                box-shadow: none;
            }


            // Elements
            .capacities-grid-venue-and-rooms-button svg {
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    &-venue-and-rooms-button {
        position: absolute;
        bottom: 0;
        left: 50%;

        padding-left: rem(40);

        transform: translate(-50%, 50%);

        user-select: none;


        // Elements
        svg {
            position: absolute;
            top: 50%;
            left: rem(5);

            width: rem(32);
            height: rem(32);

            transform: translateY(-50%);
        }
    }

    &-room {
        background: $color-striped-table-1;

        &:nth-of-type(even) {
            background-color: $color-striped-table-2;
        }

        @media(--to-medium-screen-w) {
            padding-bottom: rem(20);
        }


        // Elements
        .capacities-grid-text-cell-inner {
            padding-top: rem(16);

            @media(--to-medium-screen-w) {
                min-height: rem(55);
                padding-top: rem(20);
            }
        }

        .capacities-grid-cell {
            min-height: rem(50);
        }
    }

    &-room-meta-mobile {
        @media(--from-medium-screen-w) {
            display: none;
        }
    }

    &-room-meta-desktop {
        color: $color-dark-tint-1;
        font-family: $font-brandon;
        font-size: rem(14);

        @media(--to-medium-screen-w) {
            display: none;
        }
    }

    &-room-meta-desktop-value {
        font-weight: $weight-semi-bold;
    }

    &-venue {
        .capacities-grid-text-cell-inner {
            padding-bottom: rem(25);

            @media(--from-medium-screen-w) {
                padding-bottom: rem(10);
            }
        }

        .capacities-grid-cell {
            @media(--to-medium-screen-w) {
                display: none;
            }
        }
    }
}
