/*
    Feature Room
 */
.feature-room {
    &-badge {
        top: rem(-12);
        right: calc(rem(15) + rem($grid-spacing));
        z-index: $layer-tile-feature;

        display: flex;
        flex-direction: column;
        justify-content: center;

        font-family: $font-secondary;

        text-align: center;

        @media (--from-normal-screen-w) {
            right: calc(rem(15) + rem($grid-spacing-large));
        }
    }
}
