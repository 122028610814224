/*
    Heading
 */
h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    margin-top: 0;
    margin-bottom: rem(20);

    font-family: $font-primary;
    font-weight: $weight-light;
    line-height: 1.1;


    // Themes
    &.primary {
        font-family: $font-secondary;
        font-weight: $weight-light;
        text-indent: -0.04em; // Adjust Brandon font-alignment
    }

    &.secondary {
        font-family: $font-secondary;
        font-weight: $weight-bold;
        text-indent: -0.04em; // Adjust Brandon font-alignment
    }

    &.tertiary {
        font-family: $font-primary-light;
        font-weight: $weight-light;
    }
}

h5,
h6,
.heading-5,
.heading-6 {
    font-weight: $weight-semi-bold;
    line-height: 1.2;
}


// Headings
h1,
.heading-1 {
    margin-bottom: rem(24);

    font-size: rem(36);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(32);

        font-size: rem(38);
    }

    @media (--from-normal-screen-w) {
        font-size: rem(48);
    }
}

h2,
.heading-2 {
    margin-bottom: rem(16);

    font-size: rem(26);
    @media (--from-medium-screen-w) {
        font-size: rem(30);
    }

    @media (--from-normal-screen-w) {
        font-size: rem(36);
    }
}

h3,
.heading-3 {
    font-size: rem(24);

    @media (--from-medium-screen-w) {
        font-size: rem(26);
    }

    @media (--from-normal-screen-w) {
        font-size: rem(32);
    }
}

h4,
.heading-4 {
    font-size: rem(22);

    @media (--from-medium-screen-w) {
        font-size: rem(24);
    }

    font-weight: $weight-regular;
}

h5,
.heading-5 {
    font-size: rem(18);

    @media (--from-medium-screen-w) {
        font-size: rem(20);
    }
}

h6,
.heading-6 {
    font-size: rem(16);
}
