/*
    Disclaimer
*/
.disclaimer {
    font-size: 1rem;
    color: $color-light;
    background-color: $color-black;
    padding: rem(10);
    border-radius: rem(5);
    text-align: center;
    
    @media (--from-medium-screen-w) {
        padding: rem(20);
    }
}
