/*
    Footer Navigation
 */
.footer-navigation {
    padding-top: rem(30);
    padding-bottom: rem(30);

    background-color: $color-dark;

    @media (--to-medium-screen-w) {
        display: none;
    }

    &-inner {
        display: flex;
    }


    // Elements
    .list {
        width: 100%;

        &:not(:last-child) {
            margin-right: rem(20);
        }
    }

    .items {
        column-count: 2;
    }

    .link {
        display: block;

        color: $color-light-shade-2;
        font-family: $font-primary;


        // States
        &:hover {
            @media (--from-medium-screen-w) {
                color: $color-primary;
            }
        }


        // Types
        &:not(:last-child) {
            margin-bottom: rem(8);
        }
    }

    .title {
        display: block;

        color: $color-primary;
        text-transform: uppercase;
    }
}

