/*
    Lightbox Image Carousel
 */
.lightbox-image-carousel {

    &,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide *,
    &-item {
        width: 100%;
        height: 100%;
    }

    &-item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.35;

        transition: opacity 0.2s ease-in-out;

        .slick-slide.slick-active &{
            opacity: 1;
        }
    }
}
