/*
    Media Tile
 */
.media-tile {
    position: relative;

    &-inner {
        width: 100%;
        height: 100%;

        box-shadow: 0 rem(5) rem(10) rem(30) $color-shadow-primary;
    }
}
