/*
    Carousel
 */
$--carousel-max-height: 600;

.carousel {
    position: relative;

    overflow: hidden;

    @media(--from-medium-screen-w) {
        max-height: rem($--carousel-max-height);
    }


    // Elements
    &-controls {
        @media (--to-medium-screen-w) {
            display: flex;
            justify-content: space-between;
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);

            background-color: $color-primary;
        }
    }

    &-break {
        @media(--to-medium-screen-w) {
            background-color: $color-light;
        }
    }

    &-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media(--from-medium-screen-w) {
            max-height: rem($--carousel-max-height);
        }
    }

    &-media {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-image-carousel-slide;

        width: 100%;
        height: 100%;

        opacity: 0;

        transition: transform 1s ease, opacity 1s ease;


        // States
        &.is-active {
            z-index: $layer-image-carousel-active-slide;

            opacity: 1;

            transform: scale(1);
        }

        &.is-next {
            opacity: 0;

            transform: scale(1.2);
        }
    }

    &-break,
    &-title,
    &-text {
        z-index: $layer-image-carousel-slide;

        opacity: 0;

        transform: translateY(rem(50));
    }

    &-item {
        position: absolute;
        z-index: $layer-image-carousel-slide;

        width: 100%;
        padding-top: rem(30);
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);

        color: $color-light;

        @media(--from-medium-screen-w) {
            position: absolute;
            bottom: 0;
            left: 0;

            padding-left: rem(100);
        }


        // States
        &.is-active {
            position: relative;

            .carousel-title,
            .carousel-break,
            .carousel-text {
                position: relative;
                z-index: $layer-image-carousel-active-slide;

                opacity: 1;

                transform: translateY(0);
                transition: transform 0.4s ease, opacity 0.4s ease;
            }

            @media(--from-medium-screen-w) {
                position: absolute;
                z-index: $layer-image-carousel-active-slide;
            }


            // Elements
            .carousel-break {
                transition-delay: 0.15s;
            }

            .carousel-text {
                transition-delay: 0.3s;
            }
        }
    }

    &-info {
        overflow: hidden;

        background-color: $color-primary;

        @media(--from-medium-screen-w) {
            background-color: transparent;
        }
    }

    &-text {
        @media(--from-medium-screen-w) {
            font-size: rem(24);
        }
    }

    &-inner {
        position: relative;
    }

    .change-slide {
        position: relative;
        z-index: $layer-image-carousel-active-slide;

        display: block;

        transition:
            opacity 0.2s ease-in-out,
            transform 0.2s ease-in-out;

        @media (--from-medium-screen-w) {
            position: absolute;
            top: 50%;

            transform: translateY(-50%);
        }


        // States
        &.is-disabled {
            opacity: 0.35;
        }


        // Types
        &.next {
            @media (--from-medium-screen-w) {
                right: rem(20);
            }
        }

        &.previous {
            @media (--from-medium-screen-w) {
                left: rem(20);
            }

            .icon {
                transform: rotate(180deg);
            }
        }
    }

    .preserve-aspect-ratio {
        overflow: hidden;
    }

    .image-overlay::before {
        height: 70%;
    }

    .heading-1 {
        margin-bottom: rem(10);
    }
}
