/*
    Content Columns
 */
.content-columns {
    margin-bottom: rem(20);

    @media (--from-medium-screen-w) {
        margin-right: rem(-20);
        margin-left: rem(-20);
    }


    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }

        & > * {
            margin-bottom: rem(30);

            @media (--from-medium-screen-w) {
                flex-basis: calc(100% / 2);
                max-width: calc(100% / 2); // IE fix for flex-wrap
            }

            @media (--from-normal-screen-w) {
                flex-basis: calc(100% / 3);
                max-width: calc(100% / 3); // IE fix for flex-wrap
            }
        }
    }

    .rte-content {
        @media (--from-medium-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }
    }
}
