/*
    Print
    - Based on HTML5Boilerplate print styles
 */
@media print {
    * {
        color: rgb(0, 0, 0) !important; // Black prints faster: h5bp.com/s
        text-shadow: none !important;

        background: transparent !important;
        box-shadow: none !important;

    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: ' (' attr(href) ')';
    }

    abbr[title]:after {
        content: ' (' attr(title) ')';
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^='#']:after,
    a[href^='javascript:']:after {
        content: '';
    }

    pre,
    blockquote {
        border: 1px solid rgb(153, 153, 153);

        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
