/*
    Enquiry Section
 */
.enquiry-section {
    @media (--from-medium-screen-w) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .form {
        @media (--from-medium-screen-w) {
            flex-grow: 1;
        }
    }

    .privacy-policy {
        font-size: rem(14);

        &:not(:last-of-type) {
            margin-bottom: rem(10);
        }
    }
}
