/*
    Cite
 */
.cite {
    position: relative;

    text-indent: rem(35);


    // Elements
    &::before {
        position: absolute;
        top: rem(12);
        left: 0;

        width: rem(25);

        border-bottom: 1px solid $color-primary;
        content: '';
    }
}
