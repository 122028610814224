.enquiry-modal-form {
    width: 100%;

    @media(--from-small-screen-w) {
        max-width: rem(410);
    }


    // Elements
    &.contact {
        @media (--from-normal-screen-w) {
            width: 100%;
            max-width: 100%;
        }
    }

    &-contact {
        display: flex;

        @media (--to-normal-screen-w) {
            flex-direction: column;
        }
    }

    &-contact-fields {
        order: 2;

        @media (--from-normal-screen-w) {
            flex-basis: 50%;
        }
    }

    .submit-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: rem(40);
        margin-bottom: rem(80);


        // Elements
        .button.back {
            min-width: rem(115);
            margin-right: rem(12);

            @media (--from-small-screen-w) {
                margin-right: rem(25);
            }
        }

        .button.continue,
        .button.submit {
            min-width: rem(150);

            @media (--from-small-screen-w) {
                min-width: rem(170);
            }
        }
    }

    .mandatory-description, .error-description, .recaptcha-description {
        width: 100%;
        padding: 0 0 rem(10) 0;

        font-size: rem(14);
        text-align: center;
    }

    .privacy-link {
        display: inline-block;

        border-bottom: 1px solid $color-primary;

        transition:
            color 0.18s ease-in-out,
            border-color 0.18s ease-in-out;

        &:hover {
            color: $color-primary;

            border-color: transparent;
        }
    }
    .error-description {
        color: $color-danger;
    }

    .list-section {
        margin-bottom: rem(35);

        &-title {
            margin-bottom: rem(10);

            font-family: $font-primary;
            font-size: rem(14);
            font-weight: $weight-semi-bold;
        }
    }

    .list {
        padding: 0;
        margin: rem(10) 0 rem(35);
    }

    .list-item {
        position: relative;
        right: 0;

        display: flex;
        align-items: center;
        order: 0;
        width: 100%;
        height: rem(45);
        margin: 0;

        font-family: $font-primary;
        font-size: rem(14);
        font-weight: $weight-semi-bold;
        text-transform: uppercase;

        border-bottom: 2px solid $color-light-shade-2;


        // Elements
        &:before {
            content: '';
        }

        .icon {
            position: absolute;
            top: 50%;
            right: rem(-3);

            width: rem(26);
            height: rem(26);

            color: $color-primary;

            transform: translateY(-50%);
        }
    }

    .field.checkbox.list-item {
        margin: 0;


        // Elements
        .label {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-left: 0;
            margin-bottom: 0;
            margin-left: 0;
        }

        input[type='checkbox'] {
            position: absolute;
            right: 0;
            z-index: $layer-custom-input-field;

            opacity: 0;
        }

        .checkbox-button {
            top: 50%;
            right: 0;
            left: auto;

            transform: translateY(-50%);
        }
    }

    .radio {
        position: relative;

        display: flex;
        align-items: center;
        width: 100%;


        // Elements
        input[type='radio'] {
            position: absolute;

            width: 100%;
            height: 100%;

            opacity: 0;


            // States
            &:checked + .radio-button::after {
                position: absolute;
                top: 50%;
                left: 50%;

                width: rem(10);
                height: rem(10);
                margin: 0;

                background-color: $color-primary;
                border: 2px solid $color-primary;
                border-radius: 50%;

                content: '';

                transform: translate(-50%, -50%);
            }

            &:focus ~ .label {
                outline: -webkit-focus-ring-color auto rem(5);
                outline-offset: rem(-5);
            }
        }

        .radio-button {
            position: absolute;
            top: 50%;
            right: rem(2);
            z-index: $layer-radio-button;

            width: rem(18);
            height: rem(18);
            margin: 0;

            color: $color-dark-tint-1;

            background-color: $color-light;
            border: 2px solid $color-primary;
            border-radius: 50%;

            transform: translateY(-50%);
        }

        .label {
            z-index: $layer-radio-button;

            display: flex;
            align-items: center;
            width: 100%;
            height: rem(70);
            min-height: rem(70);
            margin: 0;

            text-transform: uppercase;

            border-bottom: 2px solid $color-light-shade-2;
        }

        .label-static {
            display: flex;
            align-items: center;
            width: 60%;

            + .label-static {
                width: 40%;
                margin-right: rem(18);
            }
        }

        .icon {
            margin-right: rem(2);

            &.layout {
                width: rem(50);
                height: rem(50);
            }
        }
    }

    .overview-section {
        margin-bottom: rem(25);

        @media (--from-normal-screen-w) {
            flex-basis: 50%;
            order: 2;
            padding-left: rem(45);
        }


        // Elements
        &-title {
            margin-bottom: rem(10);

            font-family: $font-primary;
            font-size: rem(14);
            font-weight: $weight-semi-bold;
        }

        &-list {
            padding: 0;
            margin: 0;
        }

        &-list-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;

            padding: rem(25);
            padding-left: rem(50);
            margin-bottom: 0;

            border: 2px solid $color-light-shade-2;
            border-bottom: none;
            border-radius: 2px;


            // Elements
            &:last-child {
                border-bottom: 2px solid $color-light-shade-2;
            }

            &:before {
                top: rem(25);
                left: rem(40);

                width: rem(23);
                height: rem(23);

                font-size: rem(14);

                border-radius: 50%;
            }

            ul, li {
                width: 100%;
                padding: 0;
                margin: 0;
            }

            ul {
                padding-top: rem(10);
            }

            li:before {
                padding: 0;
                margin: 0;

                content: none;
            }
        }

        &-item-title {
            flex-grow: 1;
            margin: 0;

            font-weight: $weight-regular;
        }

        &-item-description {
            padding: 0;
            margin: 0;
        }

        &-item-description::first-letter {
            text-transform: uppercase;
        }

        &-item-button {
            font-size: rem(18);
            font-weight: $weight-light;

            border-bottom: 1px solid $color-primary;

            transition: color 0.18s ease-in-out, border-color 0.18s ease-in-out;


            // States
            &:hover:not(:disabled) {
                color: $color-primary;

                border-color: transparent;
            }

            &:disabled {
                border-color: transparent;
            }
        }

        &-empty {
            width: 100%;
            padding-top: rem(10);
        }
    }

    .accordion {
        @media (--to-small-screen-w) {
            margin-right: rem(-20);
            margin-left: rem(-20);
        }

        .icon-button {
            @media (--to-small-screen-w) {
                right: rem(20);
            }
        }
    }

    .g-recaptcha {
        display: none;

        &.is-active {
            display: block;
        }
    }
}
