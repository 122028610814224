/*
    Parking availability
*/
.parking-availability-block {
    .title {
    }

    .parking-availability {
        border-top: 1px solid $color-light-shade-2;

        .inner {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-top: rem(20);

            @media (--from-normal-screen-w) {
                justify-content: flex-start;
                flex-direction: row;
            }
        }
    }

    .carpark {
        flex-basis: calc(100% / 3);
        margin-bottom: rem(20);

        &-inner {
            margin-bottom: rem(10) 0;

            @media(--from-normal-screen-w) {
                margin: 0 rem(10);
            }
        }

        &-tile {
            display: block;
            padding-top: rem(15);
            padding-right: rem(15);
            padding-bottom: rem(15);
            padding-left: rem(15);
    
            box-shadow: 1px 1px 4px 0 $color-shadow-primary;
    
            transition: box-shadow ease-in-out 150ms;
    
            @media(--from-medium-screen-w) {
                padding-top: rem(20);
                padding-right: rem(40);
                padding-bottom: rem(20);
                padding-left: rem(40);
            }
    
            &,
            &:link,
            &:visited,
            &:hover,
            &:active,
            &:focus {
                color: inherit;
                text-decoration: none;
            }
    
            &:hover,
            &:active,
            &:focus {
                box-shadow: 1px 3px 10px 0 $color-shadow-primary;
    
                .carpark-note-highlight {
                    text-decoration: underline;
                }
            }
        }
    
        &-label {
            margin-bottom: rem(4);
    
            font-size: rem(16);
    
            @media(--from-medium-screen-w) {
                font-size: rem(20);
            }
        }
    
        &-name {
            margin-bottom: rem(10);
    
            color: $color-primary;
            font-size: rem(28);
            line-height: 1.4;
    
            border-bottom: 1px solid $color-light-shade-2;
    
            @media(--from-medium-screen-w) {
                font-size: rem(40);
            }
        }
    
        &-available-spaces {
            margin-bottom: rem(4);
    
            font-weight: 700;
            font-size: rem(28);
            line-height: 1.4;
    
            @media(--from-medium-screen-w) {
                font-size: rem(40);
            }
        }
    
        &-note-highlight {
            color: $color-primary;
        }
    }

    .note {
        font-size: 1rem;
    }
}