/*
    Search
 */
.search {

    // States
    &.is-active .search-inner {
        transform: translateX(0);
        transition: transform $overlay-transition-open;
    }


    // Elements
    &-inner {
        height: rem($search-height);

        background-color: $color-primary;

        @media (--to-medium-screen-w) {
            position: fixed;
            top: 0;
            left: 0;
            z-index: $layer-header-search;

            width: 100%;
            height: 100vh;
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);
            overflow-y: scroll;

            transform: translateX(100%);
            transition: transform $overlay-transition-close;
        }

        @media (--from-medium-screen-w) {
            height: rem($search-height-large);

            color: $color-dark;

            background-color: $color-light-shade-1;
            border-bottom: 1px solid $color-light-shade-2;
        }
    }

    &-toggle {
        height: rem(50);

        line-height: rem(50);
    }

    .header-actions,
    .search-toggle {
        @media (--from-medium-screen-w) {
            display: none;
        }
    }
}
