/*
    Preserve Aspect Ratio
 */
.preserve-aspect-ratio {
    position: relative;

    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    // Types
    &.standard::before {
        padding-top: calc(3 / 4 * 100%);
    }

    &.portrait::before {
        padding-top: calc(4 / 3 * 100%);
    }


    // Elements
    &::before {
        display: block;
        padding-top: calc(9 / 16 * 100%);

        content: '';
    }

    &-media {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        .video {
            width: inherit;
            height: inherit;
        }
    }

    .fixed-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-preserve-ratio-fixed-image;

        width: 100%;
        height: auto;

        opacity: 0;
    }
}
