/*
    Style Guide
 */
.style-guide {

    // Elements
    &-section,
    &-header {
        margin-bottom: rem(80);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(100);
        }
    }

    &-header {
        padding-top: rem(50);

        @media (--from-medium-screen-w) {
            padding-top: rem(70);
        }
    }

    &-section-header {
        margin-bottom: rem(40);

        h2 {
            margin-bottom: rem(10);

            font-family: $font-secondary;
            font-weight: $weight-light;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
    }

    &-sub-heading {
        margin-bottom: rem(10);
    }

    &-swatches {
        margin-right: rem(-10);
        margin-bottom: rem(20);
        margin-left: rem(-10);

        @media (--from-tiny-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }


        // Elements
        .swatch {
            flex-basis: 100%;

            @media (--from-small-screen-w) {
                flex-basis: 50%;
            }

            @media (--from-medium-screen-w) {
                flex-basis: 25%;
            }
        }
    }

    &-button-group {
        margin-bottom: rem(30);

        border-radius: rem(3);


        // Types
        &.dark {
            padding: rem(20) rem(20) rem(10);

            background-color: $color-dark;
        }


        // Elements
        > * {
            margin-right: rem(10);
            margin-bottom: rem(10);

            vertical-align: top;
        }
    }

    &-icons > * {
        display: inline-block;
        width: rem(40);
        height: rem(40);
        margin-right: rem(10);

        border: 1px solid $color-light-shade-2;
    }

    &-line {
        padding-bottom: rem(5);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(20);

            border-bottom: rem(10) solid $color-dark;
        }


        // Sizes
        &.x-large::after {
            content: ': ' '$constrain-width-x-large' 'px';
        }

        &.large::after {
            content: ': ' '$constrain-width-large' 'px';
        }

        &.medium::after {
            content: ': ' '$constrain-width-medium' 'px';
        }

        &.small::after {
            content: ': ' '$constrain-width-small' 'px';
        }


        // Elements
        &::after {
            content: ': ' '$constrain-width' 'px';
        }
    }
}
