/*
    Enquiry Modal
*/
$--header-height: 135;
$--header-image-width: 100;
$--header-title-width: 235;

.enquiry-modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $layer-modal-active;

    width: 100%;
    height: 100%;
    overflow: scroll;

    background: $color-light;

    opacity: 1;

    @media(--from-small-screen-w) {
        display: flex;
        align-items: center;
        flex-direction: column;
    }


    // States
    &.hidden {
        z-index: $layer-modal;

        opacity: 0;
    }


    // Elements
    &-header {
        position: relative;

        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        max-height: rem($--header-height);

        border-bottom: 2px solid $color-light-shade-2;


        // Elements
        &-close-button {
            order: 2;
            width: rem(25);
            margin-right: rem(20);

            @media (--from-medium-screen-w) {
                order: 3;
                width: rem(45);
            }


            // Elements
            span {
                display: flex;
                align-items: center;
                flex-direction: column;

                color: $color-dark-tint-1;
                font-size: rem(10);
                line-height: 0;

                text-transform: uppercase;

                @media (--from-medium-screen-w) {
                    font-size: rem(14);
                    line-height: rem(20);
                }
            }
        }

        &-image, &-image-overlay {
            width: rem($--header-image-width);
            height: 100%;
            max-height: rem($--header-height);

            @media (--to-normal-screen-w) {
                display: none;
            }
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;
        }

        &-image-overlay {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-title {
            flex-grow: 1;
            order: 1;
            max-width: rem($--header-title-width);
            margin-bottom: 0;
            margin-left: rem(20);

            font-size: rem(14);
            line-height: rem(25);

            @media (--from-normal-screen-w) {
                position: absolute;
                top: 50%;
                left: rem($--header-image-width);

                font-size: rem(16);
                line-height: rem(27);

                transform: translateY(-50%);
            }
        }

        &-steps {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: center;
            order: 3;

            width: 100%;
            padding: 0;
            margin: 0;

            @media (--from-medium-screen-w) {
                justify-content: flex-start;
                width: rem(480);
            }

            @media (--from-normal-screen-w) {
                justify-content: center;
                padding-left: rem($--header-title-width);
            }

            @media (--from-large-screen-w) {
                padding-left: 0;
            }


            // Elements
            li {
                position: relative;

                flex-shrink: 0;
                width: rem(80);
                padding: 0;
                margin: 0;

                font-size: rem(14);
                text-align: center;

                @media (--from-small-screen-w) {
                    width: rem(155);
                }

                @media (--from-medium-screen-w) {
                    width: rem(120);
                }

                @media (--from-normal-screen-w) {
                    width: rem(155);
                }


                // States
                &.step-selected {
                    &::before {
                        border-color: $color-primary;
                    }
                }

                &.step-completed {
                    &::before {
                        color: $color-light;

                        background-color: $color-primary;
                        border-color: $color-primary;
                    }

                    &:not(:last-child):after {
                        background-color: $color-primary
                    }
                }


                // Elements
                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: $layer-modal-tab-bar;

                    width: rem(13);
                    height: rem(13);
                    margin: 0;

                    color: $color-dark-tint-1;

                    background-color: $color-light;
                    border: 2px solid $color-light-shade-2;
                    border-radius: 50%;

                    transform: translate(-50%, -50%);
                    transition: border-color 0.25s ease-in-out;

                    @media (--to-medium-screen-w) {
                        content: ''; // Removes ordered list numbers
                    }

                    @media (--from-medium-screen-w) {
                        top: rem(-3);

                        width: rem(23);
                        height: rem(23);

                        font-size: rem(14);
                        text-align: center;

                        transform: translateX(-50%);
                    }
                }

                &:not(:last-child):after {
                    position: absolute;
                    top: 50%;
                    left: rem(55);
                    z-index: $layer-modal-tab-bar;

                    width: rem(50);
                    height: rem(2);

                    background-color: $color-light-shade-2;

                    content: '';

                    transition: background-color 0.25s ease-in-out;

                    @media (--from-small-screen-w) {
                        left: rem(102);

                        width: rem(105);
                    }

                    @media (--from-medium-screen-w) {
                        top: rem(8);
                        left: rem(80);

                        width: rem(80);
                    }

                    @media (--from-normal-screen-w) {
                        left: rem(102);

                        width: rem(105);
                    }
                }

                button {
                    height: rem(50);

                    @media (--to-medium-screen-w) {
                        width: rem(45);

                        font-size: rem(0); // Removes button description on small screen widths
                    }

                    @media (--from-medium-screen-w) {
                        padding-top: rem(25);

                        font-size: rem(14);

                        opacity: 1;
                    }
                }
            }
        }
    }

    &-content,
    &-success {
        position: relative;

        display: flex;
        align-items: center;
        flex-direction: column;

        width: 100%;
        height: 100%;
        padding-top: rem(35);

        @media(--to-small-screen-w) {
            min-height: 100%;
        }


        // Elements
        &-title {
            text-align: center;
        }
    }

    &-success {
        &-title {
            max-width: rem(650);

            h1 {
                margin-bottom: rem(25);
            }
        }

        .block-section {
            width: 100%;

            padding: rem(50) 0 rem(150) 0;
            overflow: visible;

            background-color: transparent;

        }

        .section-header .heading-1 {
            color: $color-primary;
        }
    }
}
