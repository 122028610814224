/*
    Sub Navigation
 */
.sub-navigation {
    color: $color-light-shade-3;

    @media (--to-nav-w) {
        display: none;
    }

    @media (--from-nav-w) {
        position: absolute;
        top: 100%;
        left: 50%;

        width: rem(280);
        height: 0;
        overflow: hidden;

        color: $color-dark;

        background-color: $color-light;
        border-radius: rem(2);
        box-shadow: 0 rem(2) rem(6) color($color-dark alpha(50%));
        opacity: 0;

        transform: translate(-50%, rem(20));
        transition:
            height 0 ease-in-out 0.15s,
            overflow 0 ease-in-out 0.15s,
            transform 0.15s ease-in-out,
            opacity 0.15s ease-in-out;
    }


    // States
    &.is-active {
        @media (--to-nav-w) {
            display: none;
        }
    }


    // Elements
    &::before {
        @media (--from-nav-w) {
            position: absolute;
            top: 0;
            left: 50%;

            border: rem(6) solid transparent;
            border-bottom: rem(8) solid $color-light;

            content: '';

            transform: translateY(-100%);
        }
    }

    &-item {
        display: block;
        padding: rem(5) rem(22);

        @media (--from-nav-w) {
            font-size: rem(16);
        }


        // States
        &:hover {
            @media (--from-nav-w) {
                color: $color-primary;
            }
        }

        &.is-active {
            color: $color-primary;
        }


        // Order
        &:first-child {
            padding-top: rem(20);
        }

        &:last-child {
            padding-bottom: rem(20);
        }
    }

    &-button-item-wrapper {
        padding-top: rem(7);
        padding-right: rem(22);
        padding-bottom: rem(7);
        padding-left: rem(22);
        margin-bottom: rem(10);

        @media (--from-nav-w) {
            text-align: center;
        }
    }
}
