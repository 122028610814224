/*
    Multi-select
 */
.multi-select {
    position: relative;

    width: 100%;


    // States
    &.is-active {

        .multi-select-toggle {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .icon {
                transform: rotate(-90deg);
            }
        }

        .multi-select-options {
            display: block;
        }
    }


    // Elements
    &-hidden-input {
        display: none; // Used for housing the value but not needed

        visibility: hidden;
    }

    &-value {
        color: $color-dark;
    }

    &-toggle {
        position: relative;

        display: block;
        width: 100%;
        min-height: rem(38);
        padding-top: rem(9);
        padding-right: rem(40);
        padding-bottom: rem(10);
        padding-left: rem(8);

        color: $color-dark-tint-3;
        font-family: $font-primary;
        font-size: rem(18);
        font-weight: $weight-regular;
        line-height: rem(20);
        text-align: left;
        white-space: normal;

        border: none;
        border-radius: rem(2);
        box-shadow: 0 0 0 rem(2) $color-light-shade-2;

        .icon {
            position: absolute;
            top: rem(10);
            right: rem(16);


            width: rem(20);
            height: rem(20);

            color: $color-dark;

            transform: rotate(90deg); // Using chevron-right so needs to face down
            transition: transform 0.15s ease-in-out;
        }
    }

    &-options {
        position: absolute;
        top: 100%;
        left: rem(-2);
        z-index: $layer-multi-select-options;

        display: none;
        width: calc(100% + rem(4));
        max-height: rem(300);
        overflow-x: hidden;
        overflow-y: scroll;
        overflow-scrolling: touch;

        background-color: $color-light;
        border: rem(2) solid $color-light-shade-2;
        border-top-width: rem(1);
        border-radius: rem(2)
    }

    &-item {
        position: relative;

        display: block;
        width: 100%;
        min-height: rem(40);
        padding-right: rem(40);

        color: $color-dark-tint-1;
        font-family: $font-primary;
        font-size: rem(16);
        text-align: left;
        white-space: normal;

        border-radius: 0;


        // States
        &:hover {
            @media (--from-medium-screen-w) {
                background-color: $color-light-shade-1;
            }
        }


        // Types
        &.is-active {
            background-color: $color-light-shade-1;

            .multi-select-item-checkbox {
                background-color: $color-primary;

                .icon {
                    opacity: 1;
                }
            }
        }


        // Order
        &:not(:last-child) {
            border-bottom: rem(1) solid $color-light-shade-2;
        }


        // Elements
        &-inner {
            display: flex;
            align-items: center;
        }

        &-title {
            padding-left: rem(15);
        }

        &-image {
            flex-basis: rem(100);
            flex-shrink: 0;

            background-color: $color-dark;
            background-repeat: no-repeat;
            background-size: cover;


            // Relationship
            & + .multi-select-item-title {
                padding-left: rem(20);
            }


            // Elements
            &::before {
                display: block;
                padding-top: calc(100% * 2 / 3);

                content: '';
            }
        }

        &-checkbox {
            position: absolute;
            top: 50%;
            right: rem(15);

            width: rem(20);
            height: rem(20);
            padding: 0;

            border: rem(2) solid $color-primary;
            border-radius: rem(4);

            content: '';

            transform: translateY(-50%);

            transition: background-color 0.1s ease-in-out;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;

                width: rem(26);
                height: rem(26);
                max-width: rem(26);

                color: $color-light;

                opacity: 0;

                transform: translate(-50%, -50%);
                transition: opacity 0.15s ease-in-out;
            }
        }
    }

    &-close-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $layer-multi-select-close-overlay;

        width: 100%;
        height: 100%;
        overflow: hidden;

        border: none;
        outline: none;

        &-label {
            // For screen readers only
            position: absolute;
            left: -100%;
        }
    }
}
