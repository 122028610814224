/*
    Video Content Block
 */
.video-content-block {
    padding-top: rem(60);


    // Elements
    &-inner {
        margin-bottom: rem(20);

        @media (--to-normal-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }


        // States
        &:hover .tile-frame::before {
            transform: translateX(rem(-40)) translateY(rem(30));
        }
    }

    .section-header {
        color: $color-light;
        letter-spacing: 0.05em;

        &-subtitle {
            color: inherit;
            font-size: rem(16);
        }
    }

    .tile-frame::before {
        transition: 0.4s transform ease;
    }
}
