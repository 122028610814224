/*
    Pagebreak quote Content Block
 */

.pagebreak-quote-content-block {
    position: relative;


    // Types
    &.no-image::before {
        position: absolute;
        top: rem(-20);
        left: 50%;
        z-index: $layer-tile-frame;

        display: block;
        width: calc(rem($constrain-width) - rem(40));
        height: calc(100% + rem(40));
        max-width: calc(100% - rem(40));

        border-right: rem(150) solid $color-primary;

        content: '';

        transform: translateX(-50%);

        @media(--from-small-screen-w) {
            border-right: rem(300) solid $color-primary;
        }

        @media(--from-medium-screen-w) {
            top: rem(-30);

            height: calc(100% + rem(60));
        }

        @media(--from-large-screen-w) {
            border-right: rem(460) solid $color-primary;
        }
    }


    // Elements
    &-inner {
        // Safari bug with flex
        @media(--from-medium-screen-w) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-media {
        background-position: center;
        background-size: cover;
    }

    &-section {
        flex-basis: 50%;
        width: 100%;
        padding: rem(30);

        color: $color-light;


        // States
        &:hover .tile-frame::before {
            transform: translateX(rem(-40)) translateY(rem(30));
        }
    }

    &.no-image {
        margin-top: rem(64);
        margin-bottom: rem(64);

        @media(--from-medium-screen-w) {
            margin-top: rem(112);
            margin-bottom: rem(112);
        }
    }

    &-section:first-child:nth-last-child(1) {
        flex-basis: 100%;

        @media(--from-medium-screen-w) {
            padding-right: rem(100);
        }
    }

    .heading-2 {
        margin-bottom: rem(40);

        line-height: 1.2;
    }

    .tile-frame::before {
        transition: 0.4s transform ease;
    }
}
