/*
    Content Section
 */
.content-section {
    margin-top: rem($section-spacing);
    margin-bottom: rem($section-spacing);

    @media (--from-medium-screen-w) {
        margin-top: rem($section-spacing-large);
        margin-bottom: rem($section-spacing-large);
    }


    // Elements
    &-inner {
        @media(--from-normal-screen-w) {
            display: flex;
        }
    }

    &-main {
        @media (--to-normal-screen-w) {
            margin-bottom: rem(36);
        }

        @media (--from-normal-screen-w) {
            flex-shrink: 0;
            max-width: calc(100% - $content-sidebar-width);
        }
    }

    &-aside {
        @media(--from-normal-screen-w) {
            flex-grow: 1;
            padding-top: rem(10);
            padding-left: rem(35);
        }

        h2:first-child {
            @media(--from-normal-screen-w) {
                margin-bottom: rem(25);
            }
        }

        // Img
        img {
            height: auto !important; // Override ckeditor image inline style height
        }
    }

    .file-download {
        @media(--from-normal-screen-w) {
            max-width: 60%;
        }
    }
}
