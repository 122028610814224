/*
    Dropdown box
 */
.select-wrapper {
    position: relative;

    display: flex;
    align-self: center;

    background-color: $color-light;
    border-radius: rem(2);
    box-shadow: 0 0 0 2px $color-light-shade-2;

    @media(--from-medium-screen-w) {
        width: rem(200);
        height: rem(38);
        margin-bottom: 0;
    }


    // Elements
    select {
        width: 100%;
        height: inherit;
        padding-left: rem(12);

        font-family: inherit;

        background-color: transparent;
        border: none;
        border-radius: rem(2);

        appearance: none;


        // IE 10/11 hide arrow
        &::-ms-expand {
            display: none;
        }
    }

    .chevron {
        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        align-content: center;
        justify-content: center;
        width: 40px;
        height: 100%;

        pointer-events: none;


        // Elements
        .icon {
            width: rem(14);
            height: 100%;

            color: $color-dark;

            transform: rotate(90deg);
        }
    }
}
