/*
    RTE Content
 */
.rte-content {

    // Types
    &:not(.light) {
        h5 {
            color: $color-dark;
        }

        h6 {
            color: $color-dark-tint-1;
        }
    }

    &.light {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        li {
            color: $color-light;
        }
    }


    // Elements
    & > *:last-child {
        margin-bottom: 0; // Enforces correct wrapper margins
    }
}
