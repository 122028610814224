/*
    Image Carousel
 */
.image-carousel {
    @media (--from-medium-screen-w) {
        width: calc(100% - rem(40));
    }


    // Elements
    .slick-list {
        overflow: visible;

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;
            width: rem(170);
            height: rem(282);

            background-color: $color-primary;

            content: '';

            transform: translate(rem(-12), rem(12));

            @media (--from-small-screen-w) {
                width: rem(300);
                height: rem(360);
            }

            @media (--from-medium-screen-w) {
                left: rem(32);

                width: rem(450);
                height: rem(467);
            }

            @media (--from-normal-screen-w) {
                height: rem(492);
            }
        }
    }

    .slick-track {
        height: rem(250);
        overflow: hidden;

        @media (--from-small-screen-w) {
            height: rem(325);
        }

        @media (--from-medium-screen-w) {
            height: rem(425);
        }

        @media (--from-normal-screen-w) {
            height: rem(450);
        }
    }

    &-item {
        position: relative;

        height: rem(250);

        background-color: $color-dark-tint-1;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.35;

        transition: opacity 0.2s ease-in-out;

        @media (--from-small-screen-w) {
            height: rem(325);
        }

        @media (--from-medium-screen-w) {
            height: rem(425);
        }

        @media (--from-normal-screen-w) {
            height: rem(450);
        }


        // Types
        .slick-slide.slick-active & {
            margin-left: rem(20);
    
            opacity: 1;
    
            @media (--from-small-screen-w) {
                margin-left: rem(23);
            }
    
            @media (--from-medium-screen-w) {
                margin: 0 rem(20) 0 rem(50);
            }
        }


        // Order
        &:not(:last-of-type) {
            padding-right: rem(20);
        }


        // Elements
        &::before {
            display: block;
            flex-grow: 1;

            content: '';
        }

        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 100%;
        }

        &-expand {
            position: absolute;
            top: rem(20);
            right: rem(40);

            background-color: color($color-dark alpha(25%));
            border: 0;

            @media (--from-medium-screen-w) {
                top: rem(25);
                right: rem(25);
            }


            // States
            &:hover {
                background-color: $color-dark;
            }


            // Elements
            .icon {
                width: rem(25);
                height: rem(25);
            }
        }
    }

    // Title nested inside of slides for screen readers
    .screen-reader-only-text {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}
