/*
    Accordion
 */
.accordion {
    margin-bottom: rem(20);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(40);
    }


    // Elements
    &-item {
        border-top: 1px solid $color-light-shade-3;


        //States
        &.is-active {

            // Elements
            .icon-button {
                opacity: 0.35;

                transform: rotate(45deg);
            }

            .accordion-item-content {
                display: block;
            }
        }


        // Types
        &:last-child {
            border-bottom: 1px solid $color-light-shade-3;
        }


        // Elements
        &-header {
            position: relative;

            width: 100%;
            min-height: rem(40);
            padding-top: rem(20);
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);
            overflow: hidden;

            text-align: left;

            background-color: transparent;
            border: none;

            appearance: none;

            @media(--from-medium-screen-w) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }


            // Elements
            .icon-button {
                transition: 0.15s transform ease-in-out, opacity 0.15s;

                @media(--to-medium-screen-w) {
                    position: absolute;
                    top: rem(10);
                    right: 0;
                }
            }
        }

        &-title {
            width: 100%;
            padding-right: rem(50);
            margin-bottom: 0;

            white-space: normal;
        }

        &-content {
            display: none;
            padding-top: rem(20);
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);

            @media (--from-medium-screen-w) {
                padding-bottom: rem(20);
            }
        }
    }

    .heading-1 {
        margin-bottom: rem(48);
    }
}
