/*
    Footer Sponsors
*/
.footer-sponsors {

    // Elements
    &-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: rem(32);

        border-bottom: rem(1) solid $color-light-shade-2;

        @media (--from-normal-screen-w) {
            flex-direction: row;
        }
    }

    &-list {

        // Types
        &.supporters {
            margin-right: rem(50);
        }

        &.partners {
            flex-grow: 1;
        }


        // Pseudo
        &::before {
            content: none;
        }


        // Elements
        .title {
            text-transform: uppercase;
        }

        .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;


            // Elements
            .link {
                display: flex;
                align-items: center;
                margin-bottom: rem(32);

                border: none;

                transition: opacity 0.25s ease-in-out;


                // State
                &:hover {
                    opacity: 0.5;
                }
            }

            .logo {
                max-width: rem(60);
                max-height: rem(45);

                @media (--from-medium-screen-w) {
                    max-width: rem(75);
                }

                &.is-landscape {
                    max-width: rem(85);

                    @media (--from-medium-screen-w) {
                        max-width: rem(115);
                    }
                }
            }
        }
    }
}
