/*
    Error Page
 */
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--from-medium-screen-w) {
        height: 100vh;
    }

    @media (--from-normal-screen-w) {
        min-height: rem(800);
    }
}
