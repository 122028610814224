/*
    Enquiry Card
 */
.enquiry-card {
    flex-shrink: 0;

    margin-bottom: rem(50);

    box-shadow: 0 rem(5) rem(30) color($color-dark alpha(20%));

    @media (--from-medium-screen-w) {
        flex-basis: rem(375);
        margin-right: rem(70);
    }

    @media (--from-large-screen-w) {
        margin-right: rem(150);
    }


    // Elements
    &-photo {
        display: block;
        width: 100%;
        height: auto;
    }

    &-contacts {
        padding-top: rem(10);
        padding-bottom: rem(16);

        font-style: inherit;
    }

    &-contact {
        position: relative;

        display: block;
        padding-top: rem(16);
        padding-right: rem(20);
        padding-bottom: rem(16);
        padding-left: rem(56);

        font-family: $font-primary;
        font-size: rem(14);
        font-weight: $weight-semi-bold;

        @media (--from-medium-screen-w) {
            font-size: rem(16);
        }


        // Elements
        .icon {
            position: absolute;
            top: rem(8);
            left: rem(16);

            color: $color-primary;
        }

        .icon.phone-icon {
            top: 50%;

            transform: translateY(-50%);
        }

        .phone {
            display: block;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
        padding-bottom: rem(35);
    }
}
