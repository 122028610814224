/*
    Media
 */
picture,
img,
svg {
    max-width: 100%;
}

picture,
img {
    display: block;
}

figure {
    margin: 0;
}


iframe {
    width: 100%;
}

use {
    width: 100%;
    height: 100%;
}
