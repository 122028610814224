/*
    Form
 */
.form {

    // Elements
    .label {
        display: block;
        margin-bottom: rem(8);

        font-family: $font-primary;
        font-size: rem(14);


        // Elements
        &-static {
            margin-right: rem(5);

            font-weight: $weight-semi-bold;
        }

        &-validation {
            color: $color-danger;
            font-style: italic;
        }

        a.link {
            display: inline-block;

            border-bottom: 1px solid $color-primary;

            transition:
                color 0.18s ease-in-out,
                border-color 0.18s ease-in-out;

            &:hover {
                color: $color-primary;

                border-color: transparent;
            }
        }
    }

    .field {
        display: block;
        margin-bottom: rem(25);


        // Types
        &:last-child {
            margin-bottom: 0;
        }

        &.checkbox {
            position: relative;

            display: flex;

            .label  {
                order: 1;
                padding-left: rem(15);
            }
        }


        // Elements
        .checkbox-button {
            position: absolute;
            left: 0;

            width: rem(20);
            height: rem(20);

            background-color: $color-light;
            border: 2px solid $color-primary;
            border-radius: rem(4);

            content: '';

            transition: background-color 0.1s ease-in-out;

            .icon {
                position: absolute;
                top: 50%;
                left: rem(-5);

                width: rem(26);
                height: rem(26);
                max-width: rem(26);

                color: $color-light;

                opacity: 0;

                transform: translateY(-50%);
                transition: opacity 0.15s ease-in-out;
            }
        }
    }

    input[type='checkbox'] {
        z-index: $layer-custom-input-field;

        order: 0;
        width: rem(20);
        height: rem(20);

        opacity: 0;


        // States
        &:focus + .checkbox-button {
            outline: 0;
        }

        &:checked + .checkbox-button {
            background-color: $color-primary;

            .icon {
                opacity: 1;
            }
        }
    }

    .g-recaptcha {
        margin-bottom: rem(25);
    }
}
