/*
    Tile Frame
 */
.tile-frame {

    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 70%;

        background-color: $color-primary;

        content: '';

        transform: translate(rem(-12), rem(12));

        @media (--from-tiny-screen-w) {
            transform: translate(rem(-22), rem(22));
        }
    }

    &-image {
        position: relative;

        box-shadow:
            0 rem(2) rem(10) $color-shadow-primary,
            0 rem(6) rem(30) $color-shadow-primary;
    }
}
