/*
    Quote
 */
blockquote,
q {
    width: 100%;
    max-width: rem(650);
    padding: rem(20);
    margin-bottom: rem(40);
    margin-left: 0;

    text-align: center;

    border: rem(8) solid $color-primary;
    box-shadow: 0 rem(6) rem(30) 0 color($color-dark alpha(32%));

    quotes: '“' '”' '‘' '’';

    @media (--from-small-screen-w) {
        padding: rem(24);
    }


    // Elements
    &,
    p {
        margin-bottom: 0;

        color: $color-dark-tint-3;
        font-size: rem(14);
        font-weight: $weight-semi-bold;

        em {
            font-style: normal;

            &::before {
                content: '– ';
            }
        }
    }

    p:first-child {
        margin-bottom: rem(48);

        color: $color-dark;
        font-family: $font-secondary;
        font-size: rem(24);
        font-weight: $weight-bold;

        @media (--from-small-screen-w) {
            font-size: rem(28);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(30);
        }


        // Elements
        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }
}

cite {
}
