/*
    Caption Carousel
 */
.caption-carousel {
    position: absolute;

    width: 100%;
    height: rem(215);

    color: $color-dark;

    @media (--from-small-screen-w) {
        height: rem(125);
    }

    @media (--from-medium-screen-w) {
        width: calc(100% - rem(40));
        height: rem(145);
    }


    // Elements
    &-item {
        position: absolute;
        top: 0;
        right: 0;
        bottom: rem(25);
        left: 0;

        width: 100%;
        height: 100%;
        padding: rem(35) rem(20) rem(25) rem(20);
        overflow: hidden;

        background-color: $color-light;
        box-shadow: 0 rem(10) rem(30) color($color-dark alpha(20%));
        opacity: 0;

        @media (--from-small-screen-w) {
            bottom: rem(76);

            width: rem(485);

            padding: rem(20) rem(35) rem(25) rem(50);
        }

        @media (--from-medium-screen-w) {
            width: rem(575);
            padding: rem(30) rem(75) rem(30) rem(50);
        }


        // States
        &.is-active {
            position: absolute;
            top: rem(-20);
            z-index: $layer-caption-carousel-slide;

            opacity: 1;

            @media (--from-medium-screen-w) {
                margin-left: rem(19);
            }

            .content {
                opacity: 1;
            }
        }


        // Elements
        h3 {
            margin-bottom: rem(10);
        }

        p {
            font-size: rem(18);

            @media (--from-small-screen-w) {
                font-size: rem(20);
            }

        }

        .content {
            opacity: 0;

            transition: opacity 0.25s ease-in-out;
        }
    }

    &-change-slide {
        position: absolute;
        z-index: $layer-caption-carousel-slide;

        width: rem(56);
        height: rem(56);

        overflow: hidden;

        appearance: none;

        @media (--to-small-screen-w) {
            bottom: rem(40);
        }


        // States
        &.is-disabled {
            opacity: 0.35;
        }


        // Types
        &.previous {
            right: calc(100% - rem(72));

            @media (--from-small-screen-w) {
                top: rem(25);
                right: rem(100);
            }

            @media (--from-medium-screen-w) {
                top: rem(40);
                right: rem(72);
            }

            .icon {
                transform: rotate(180deg);
            }
        }

        &.next {
            right: rem(20);

            @media (--from-small-screen-w) {
                top: rem(25);
                right: rem(20);
            }

            @media (--from-medium-screen-w) {
                top: rem(40);
                right: rem(-10);
            }
        }
    }

    &-buttons {
        position: absolute;
        bottom: rem(-45);

        display: flex;
        justify-content: center;
        width: 100%;
        height: rem(30);

        @media (--from-small-screen-w) {
            justify-content: flex-start;
            padding-left: rem(50);
        }

        @media (--from-medium-screen-w) {
            display: none;
        }

        .slide-button {
            width: rem(14);
            height: rem(14);
            margin: 0 rem(5);

            background-color: $color-light-shade-2;
            border-radius: 50%;

            &.is-active {
                background-color: $color-dark;
            }
        }
    }
}
