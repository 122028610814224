/*
    Navigation Bar
 */
.navigation-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--from-nav-w) {
        position: relative;

        flex-grow: 1;
        padding-right: rem($button-width);
    }


    // States
    &.is-active &-inner {
        transform: translateX(0);
        transition: transform $overlay-transition-open;
    }


    // Elements
    &-inner {
        @media (--to-nav-w) {
            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            height: 100vh;
            padding-right: rem(20);
            padding-bottom: rem(20);
            padding-left: rem(20);
            overflow-y: scroll;

            background-color: $color-dark;

            transform: translateX(100%);
            transition: transform $overlay-transition-close;
        }

        @media (--from-nav-w) {
            display: flex;
            align-items: center;
        }
    }

    .header-actions {
        @media (--from-nav-w) {
            display: none;
        }
    }
}
