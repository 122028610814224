/*
    Logo
 */
.logo {

    // Types
    &.rfa img {
        width: auto;
        height: auto;
        max-width: rem(220);
        max-height: rem(100);
    }


    // Elements
    img {
        width: auto;

        @media (--to-small-screen-w) {
            height: rem(40);
        }
    }
}
