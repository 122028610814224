/*
    Submission section of a form
 */
.form-submission {

    // Elements
    &-button {
        width: rem(140);
        min-width: rem(140);
        margin-right: rem(14);


        // Elements
        .icon {
            width: rem(36);
            height: rem(36);
        }
    }

    &-validation {
        font-family: $font-primary;
        font-size: rem(14);
        font-style: italic;


        // States
        &.is-inactive {
            margin-bottom: 0;

            opacity: 0;
            visibility: hidden;

            transition: margin-bottom 0.4s ease-in-out, opacity 0.7s ease-in-out;
        }

        &.is-active {
            margin-bottom: rem(8);

            opacity: 1;
            visibility: visible;

            transition: margin-bottom 0.4s ease-in-out, opacity 0.7s ease-in-out;
        }


        // Elements
        &.error {
            color: $color-danger;
        }
    }
}
