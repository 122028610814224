/*
    Filter Group
 */
$--button-border: rem(2) solid $color-light-shade-2;
$--transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.19s ease-in-out;

.filter-group {
    margin-bottom: rem(20);

    @media (--to-normal-screen-w) {
        border-top: rem(1) solid $color-light-shade-2;
        border-bottom: rem(1) solid $color-light-shade-2;
    }


    // Elements
    &-title {
        text-align: center;

        @media (--to-normal-screen-w) {
            display: none;
        }
    }

    &-toggle {
        display: block;
        width: 100%;

        font-family: $font-secondary;
        font-size: rem(14);
        font-weight: $weight-bold;
        line-height: 1;
        letter-spacing: 0.05em;
        text-align: center;
        text-transform: uppercase;

        @media (--from-small-screen-w) {
            font-size: rem(16);
        }

        @media (--to-normal-screen-w) {
            outline: none;
        }

        @media (--from-normal-screen-w) {
            display: none;
        }


        // States
        &.is-active .icon {
            transform: rotate(-90deg);
        }


        // Elements
        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: rem(20) rem(10);
        }

        .icon {
            position: relative;
            top: -2px;

            width: rem(14);
            height: rem(14);
            margin-left: rem(10);

            transform: rotate(90deg);
            transition: $--transition, 0.1s transform ease-in-out;
        }
    }

    &-scroll-button {
        position: fixed;
        bottom: rem(20);
        left: 50%;
        z-index: $layer-fixed-scroll-button;

        min-width: rem(250);

        box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.3);

        transform: translate(-50%, 0);
        transition:
            0.2s ease-in-out opacity,
            0.3s ease-in-out transform;

        @media (--from-medium-screen-w) {
            bottom: rem(30);
        }


        // States
        &:not(.is-visible) {
            opacity: 0;

            transform: translate(-50%, rem(100));
        }


        // Elements
        .icon {
            flex-shrink: 0;
            width: rem(20);
            height: rem(20);
            margin-right: rem(6);
            margin-left: rem(-4);

            transform: rotate(-90deg);

            @media (--from-small-screen-w) {
                margin-right: rem(10);
            }
        }
    }

    &-actions {
        justify-content: center;
        padding-top: rem(20);
        margin-bottom: rem(20);

        @media (--to-normal-screen-w) {
            display: none; // !is-active
            flex-wrap: wrap;
            padding-top: rem(10);
        }

        &.is-active {
            display: flex;
        }
    }

    &-button {
        display: flex;
        flex-basis: calc(100% / 2);
        flex-grow: 1;
        max-width: calc(100% / 2);

        outline: none;

        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @media (--from-normal-screen-w) {
            flex-basis: rem(120);
            flex-grow: 1;
            max-width: 100%;
        }


        // States
        &:hover {
            .filter-group-button-label-inner {
                background-color: $color-primary;
                border-color: $color-primary;
            }

            .icon {
                color: $color-dark-tint-1;
            }
        }

        // States
        &.is-active {
            .filter-group-button-label-inner {
                border-color: $color-primary;
            }

            .icon {
                color: $color-dark-tint-1;
            }
        }


        // Order
        @media (--to-small-screen-w) {
            &:nth-child(2n+1) .filter-group-button-label {
                padding-left: rem(8);

                border-left: $--button-border;
                border-top-left-radius: rem(100);
                border-bottom-left-radius: rem(100);
            }

            &:nth-child(2n) .filter-group-button-label {
                padding-right: rem(8);

                border-right: $--button-border;
                border-top-right-radius: rem(100);
                border-bottom-right-radius: rem(100);
            }
        }

        @media (--from-normal-screen-w) {
            &:last-child .filter-group-button-label {
                padding-right: rem(12);

                border-right: $--button-border;
                border-top-right-radius: rem(100);
                border-bottom-right-radius: rem(100);
            }

            &:first-child .filter-group-button-label {
                padding-left: rem(12);

                border-left: $--button-border;
                border-top-left-radius: rem(100);
                border-bottom-left-radius: rem(100);
            }
        }

        @media (--from-small-screen-w) and (--to-normal-screen-w) {
            &:nth-child(3n+1) .filter-group-button-label {
                padding-left: rem(12);

                border-left: $--button-border;
                border-top-left-radius: rem(100);
                border-bottom-left-radius: rem(100);
            }

            &:nth-child(3n) .filter-group-button-label {
                padding-right: rem(12);

                border-right: $--button-border;
                border-top-right-radius: rem(100);
                border-bottom-right-radius: rem(100);
            }
        }


        // Elements
        &-inner {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-grow: 1;
            flex-wrap: wrap;
            width: 100%;

            @media (--to-normal-screen-w) {
                padding-bottom: rem(10);
            }
        }

        &-label {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;
            width: 100%;
            padding: rem(6) 0;

            font-family: $font-secondary;
            font-size: rem(14);
            font-weight: $weight-bold;
            line-height: 1;
            letter-spacing: 0.05em;
            text-align: center;
            text-transform: uppercase;

            border-top: $--button-border;
            border-bottom: $--button-border;

            @media (--from-small-screen-w) {
                padding: rem(8) 0;

                font-size: rem(16);
            }

            &-inner {
                width: 100%;
                padding: rem(10) rem(20);

                border: rem(2) solid transparent;
                border-radius: rem(100);

                transition: $--transition;
            }
        }

        .icon {
            width: rem(44);
            height: rem(44);
            margin-bottom: rem(5);

            color: $color-dark-tint-3;

            transition: $--transition;

            @media (--from-normal-screen-w) {
                margin-bottom: rem(15);
            }
        }
    }
}
