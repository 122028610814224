/*
    Notification
 */
.notification {
    position: relative;

    color: $color-light;


    // Types
    &.positive {
        background-color: $color-primary;
    }

    &.neutral {
        color: $color-dark;

        background-color: $color-light-shade-1;
    }

    &.negative {
        background-color: $color-dark-tint-1;
    }


    // States
    &:not(.is-visible) {
        display: none;
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;

        padding-top: rem(15);
        padding-bottom: rem(15);
    }

    &-content {
        flex-basis: 100%;

        @media(--to-small-screen-w) {
            padding-right: rem(20);
        }

        @media(--from-small-screen-w) {
            padding-left: rem(25);
        }


        // Elements
        &-title {
            font-family: $font-primary;
            font-weight: $weight-bold;
        }
    }

    &-icon {
        display: none;

        @media(--from-small-screen-w) {
            display: block;
        }
    }

    p {
        margin-bottom: 0;

        font-size: rem(14);
        line-height: 1.4;

        @media (--from-medium-screen-w) {
            font-size: rem(15);
        }
    }

    a:not(.button) {
        text-decoration: underline;

        border-bottom: none;

        &,
        &:hover {
            color: inherit;
        }
    }

    &-close {
        width: rem(40);
        height: rem(40);

        color: inherit;

        border: none;

        &,
        &:hover {
            background: transparent;
        }
    }
}
