/*
    Page Header Video
 */
.page-header-video {

    // Elements
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: '';
    }

    iframe {
        position: absolute;
        top: -100%;
        right: 0;
        bottom: -100%;
        left: 0;

        width: 100%;
        height: 300%;
    }
}
