/*
    Other rooms in venue.
 */
.other-rooms-in-venue {

    // States
    &.is-expanded {

        // Elements
        .other-rooms-in-venue-button svg {
            transform: translateY(-50%) rotate(45deg);
        }
    }


    // Elements
    &-button {
        position: relative;

        display: block;
        padding-left: rem(40);
        margin-top: rem(60);
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;

        user-select: none;

        @media (--from-medium-screen-w) {
            margin-top: rem(40);
        }

        // Elements
        svg {
            position: absolute;
            top: 50%;
            left: rem(5);

            width: rem(32);
            height: rem(32);

            transform: translateY(-50%);
        }
    }
}