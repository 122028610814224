/*
    Location Map
 */
.location-map {
    margin-bottom: rem(40);

    &-container {
        height: rem(300);

        @media (--from-small-screen-w) {
            height: rem(400);
        }
    }

    &-element {
        height: 100%;
    }
}
