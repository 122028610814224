/*
    Slick
 */
.slick-slide {
    height: auto;
}

.slick-slider .slick-track {
    transition: transform 0.6s cubic-bezier(0.16, 0.84, 0.44, 1); // easeOutQuart
}
