/*
    Icon Button
 */
.icon-button {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: rem(50);
    height: rem(50);
    padding-top: rem(6);
    padding-right: rem(6);
    padding-bottom: rem(6);
    padding-left: rem(6);

    color: $color-light;
    font-family: $font-secondary;
    font-size: rem(16);
    font-weight: $weight-regular;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    cursor: pointer;
    background-color: $color-dark;
    border: 2px solid currentColor;
    border-radius: rem(99);

    transition: background-color 0.3s, border-color 0.3s, color 0.3s, opacity 0.3s;

    @media (--to-small-screen-w) {
        width: auto;
    }


    // States
    &:hover {
        background-color: $color-dark-tint-2;
    }


    // Types
    &.primary {
        background-color: $color-primary;


        // States
        &:hover {
            opacity: 0.8;
        }
    }

    &.alternative-dark {
        color: $color-dark;

        background-color: transparent;
    }

    &.alternative-light {
        color: $color-light;

        background-color: transparent;
    }

    &.small {
        width: rem(44);
        height: rem(44);

        .icon {
            width: rem(20);
            height: rem(20);
        }
    }

    &.large {
        width: rem(56);
        height: rem(56);

        border-width: 3px
    }


    // Elements
    &-text {
        min-width: rem(200);
        padding-top: rem(10);
        padding-bottom: rem(10);

        text-align: center;

        @media (--from-small-screen-w) {
            display: none;
        }
    }

    // If there's text
    .icon {
        max-height: 100%;


        // Types
        &:not(:only-child) {
            display: none;

            @media (--from-small-screen-w) {
                display: block;
                width: rem(34);
                height: rem(34);
            }
        }
    }
}
