/*
    List
 */
ul,
ol {
    padding-left: rem(30);
    margin-top: 0;
    margin-bottom: rem(30);

    list-style: none;
}

li {
    margin-bottom: rem(16);

    &::before {
        display: inline-block;
        width: rem(20);
        margin-left: rem(-20);

        content: '';
    }
}

ul li::before {
    position: relative;
    left: rem(-5);

    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDEwIDE1Ij4gIDxwYXRoIGZpbGw9InJnYigyMDIsIDIwMiwgMjAyKSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNSwwIEwxMCw3LjUgTDAsNy41IEw1LDAgWiBNNSwxNSBMMCw3LjUgTDEwLDcuNSBMNSwxNSBaIi8+PC9zdmc+');
}

ol {
    counter-reset: counter;

    li {
        position: relative;

        &::before {
            position: absolute;
            top: rem(4);
            left: rem(-6);

            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(18);
            height: rem(18);

            color: $color-light;
            font-size: rem(11);
            line-height: rem(18);
            text-align: center;

            background-color: $color-primary;
            border-radius: rem(9);

            content: counter(counter);
            counter-increment: counter;
        }
    }
}
