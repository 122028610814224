/*
    Line
 */
hr {
    width: rem($hr-width);
    height: 2px;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: rem(20);
    margin-left: 0;

    background-color: $color-primary;
    border: 0;

    &.small {
        width: rem($hr-width-small);
    }
}
