/*
    Page Header Carousel
 */
.page-header-carousel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;


    // States
    &.homepage {

        // Elements
        .slick-dots {
            right: rem(20);
            bottom: rem(55);
            left: auto;

            @media (--from-medium-screen-w) {
                right: rem(36);
                bottom: rem(50);
            }

            @media (--from-normal-screen-w) {
                bottom: rem(100);
            }

            @media (--from-large-screen-w) {
                bottom: rem(15);
                left: calc(50vw + rem(545));
            }
        }
    }

    &.is-single-slide .slick-dots {
        visibility: hidden;
    }


    // Elements
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide * {
        height: 100%;
    }

    &-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
    }

    .slick-dots {
        position: absolute;
        bottom: rem(13);
        left: rem(20);

        padding-left: 0;
        margin-bottom: 0;

        white-space: nowrap;

        @media (--from-medium-screen-w) {
            right: rem(36);
            bottom: rem(50);
            left: auto;
        }

        @media (--from-normal-screen-w) {
            bottom: rem(100);
        }

        @media (--from-large-screen-w) {
            right: auto;
            bottom: rem(15);
            left: calc(50vw + rem(530));
        }


        // Elements
        li {
            display: inline-block;
            margin-right: rem(5);
            margin-bottom: 0;

            &:before {
                content: none;
            }


            // States
            &.slick-active button {
                background: $color-light;
            }
        }

        button {
            width: rem(12);
            height: rem(12);

            color: transparent;

            background: transparent;
            border: 1px solid $color-light;
            border-radius: 50%;

            @media (--from-large-screen-w) {
                width: rem(15);
                height: rem(15);
            }
        }
    }
}
