/*
    Notification Banner
*/
.notification-banner {
    
    // States
    &.is-hidden {
        display: none;
    }
}
