/*
    Gallery
 */
.gallery {
    padding-bottom: rem(300);

    @media (--from-medium-screen-w) {
        padding-bottom: rem(180);
    }


    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            position: relative;

            padding-right: rem(20);
            padding-left: rem(20);
            margin-right: auto;
            margin-left: auto;
        }
    }
}
