/*
    Button
 */
button {
    padding: 0;

    line-height: 1;
    white-space: nowrap;

    cursor: pointer;
    background: none;
    border: none;


    // Types
    &[type='button'],
    &[type='reset'],
    &[type='submit'] {
        appearance: none;
    }
}
