/*
    Fonts
*/
@font-face {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 300;
    src: url('../../static/fonts/brandon-grotesque/3462D9_1_0.woff2') format('woff2'),
        url('../../static/fonts/brandon-grotesque/3462D9_1_0.woff') format('woff');
}

@font-face {
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    src: url('../../static/fonts/brandon-grotesque/3462D9_7_0.woff2') format('woff2'),
        url('../../static/fonts/brandon-grotesque/3462D9_7_0.woff') format('woff');
}

@font-face {
    font-family: Brandon Grotesque;
    font-style: bold;
    font-weight: 700;
    src: url('../../static/fonts/brandon-grotesque/3462D9_3_0.woff2') format('woff2'),
        url('../../static/fonts/brandon-grotesque/3462D9_3_0.woff') format('woff');
}
