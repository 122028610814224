/*
    Content Blocks
 */
.content-blocks {

    // Elements
    .call-to-action:nth-of-type(even)  {

        // Elements
        .call-to-action-media {
            @media (--from-medium-screen-w) {
                order: 1;
            }
        }

        .call-to-action-content {
            @media (--from-medium-screen-w) {
                order: 0;
                align-items: flex-end;
            }
        }
    }

    // Relationship
    .text-content-block + .file-content-block,
    .file-content-block + .file-content-block {
        margin-top: rem(-40);

        @media (--from-medium-screen-w) {
            margin-top: rem(-50);
        }
    }

    // Order
    .content-section:last-child {
        margin-bottom: 0;
    }
}
