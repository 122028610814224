/*
    Video
 */
.video {
    padding: 0;
    margin: 0;

    background-color: $color-dark-tint-1;
    border: none;
}
