/*
    Capacities grid on venue pages.
 */
.venue-capacities-grid {
    max-width: rem($constrain-width);
    margin-right: auto;
    margin-left: auto;

    @media(--from-medium-screen-w) {
        padding-right: rem(20);
        padding-left: rem(20);
    }


    // Elements
    &-title {
        margin-bottom: rem(30);

        @media(--to-medium-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }
    }

    &-block {
        @media(--from-medium-screen-w) {
            max-width: rem(660);
        }

        @media(--from-normal-screen-w) {
            max-width: none;

            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &-heading-wrapper {
        display: none; // The heading is hidden on mobile

        @media(--from-normal-screen-w) {
            display: block;

            background-color: $color-light;
        }
    }

    &-heading {
        border-bottom: rem(1) solid $color-light-shade-2;


        // Elements
        .venue-capacities-grid-text-cell-inner {
            padding-top: rem(42);

            color: $color-dark-tint-1;
            font-size: rem(16);
        }

        .venue-capacities-grid-cell-inner {
            padding-top: rem(20);
            padding-right: 0;
            padding-bottom: rem(25);
            padding-left: 0;

            font-family: $font-brandon;
            font-size: rem(14);
            font-weight: $weight-semi-bold;
            text-align: center;


            // Elements
            svg {
                display: block;
                width: rem(34);
                height: rem(34);
                margin-right: auto;
                margin-bottom: rem(5);
                margin-left: auto;
            }
        }
    }

    &-row {
        @media(--from-medium-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }

        @media(--from-normal-screen-w) {
            flex-wrap: nowrap;
        }
    }

    &-text-cell {
        background: $color-light;

        @media(--from-medium-screen-w) {
            flex-basis: 100%;
        }

        @media(--from-normal-screen-w) {
            flex-grow: 1;

            background: none;
        }
    }

    &-text-cell-inner {
        min-height: rem(80);
        padding-top: rem(30);
        padding-right: rem(30);
        padding-bottom: rem(30);
        padding-left: rem(30);

        color: $color-primary;
        font-size: rem(16);
        text-align: center;

        @media(--from-medium-screen-w) {
            text-align: left;
        }

        @media(--from-normal-screen-w) {
            padding-bottom: rem(10);

            font-size: rem(14);
        }


        // Elements
        a {
            position: relative;

            display: inline-block;
            padding-right: rem(30);
            padding-left: rem(30);

            @media(--from-normal-screen-w) {
                display: block;
                padding-left: rem(0);
            }


            // Elements
            svg {
                position: absolute;
                top: rem(-2);
                right: 0;

                width: rem(30);
                height: rem(30);

                @media(--from-normal-screen-w) {
                    top: rem(-4);
                }
            }
        }
    }

    &-cell {
        display: none;

        @media(--from-medium-screen-w) {
            flex-basis: 50%;
        }

        @media(--from-normal-screen-w) {
            display: block;
            flex-basis: rem(110);
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &-cell-label {
        flex-grow: 1;

        @media(--from-normal-screen-w) {
            display: none; // Not showing on desktop
        }
    }

    &-cell-label-inner {
        padding-top: rem(12);
        padding-left: rem(55);

        font-family: $font-brandon;
        font-size: rem(14);
        font-weight: $weight-semi-bold;


        // Elements
        svg {
            width: rem(30);
            height: rem(30);
            margin-right: rem(14);

            vertical-align: rem(-10);
        }
    }

    &-cell-value-inner {
        padding-top: rem(17);
        padding-right: rem(50);
        padding-bottom: rem(10);
        padding-left: rem(10);

        font-family: $font-primary;
        font-size: rem(14);
        font-weight: $weight-semi-bold;
        text-align: right;

        @media(--from-normal-screen-w) {
            padding-top: rem(35);
            padding-right: rem(10);

            text-align: center;
        }
    }

    &-venue-and-rooms {
        position: relative;

        margin-bottom: rem(40);

        background-color: $color-light;


        // States
        &.is-active {
            // Elements
            .venue-capacities-grid-rooms {
                display: block;
            }

            .venue-capacities-grid-venue-and-rooms-button svg {
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }

    &-venue-and-rooms-button {
        position: absolute;
        bottom: 0;
        left: 50%;

        min-width: auto;
        padding-left: rem(40);
        overflow: hidden;

        transform: translate(-50%, 50%);
        user-select: none;

        @media(--to-normal-screen-w) {
            display: none;
        }


        // Elements
        svg {
            position: absolute;
            top: 50%;
            left: rem(5);

            width: rem(32);
            height: rem(32);

            transform: translateY(-50%);
        }
    }

    &-rooms {
        @media(--from-normal-screen-w) {
            display: none;
        }
    }

    &-button {
        position: absolute;
        bottom: 0;
        left: 50%;

        min-width: auto;
        padding-left: rem(40);
        overflow: hidden;

        transform: translate(-50%, 50%);
        user-select: none;

        @media(--from-medium-screen-w) {
            top: rem(20);
            right: rem(40);
            bottom: auto;
            left: auto;

            transform: none;
        }

        @media(--from-normal-screen-w) {
            display: none;
        }


        // Elements
        svg {
            position: absolute;
            top: 50%;
            left: rem(5);

            width: rem(32);
            height: rem(32);

            transform: translateY(-50%);
        }
    }

    &-room {
        position: relative;

        margin-bottom: rem(35);

        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

        @media(--to-normal-screen-w) {
            background: $color-striped-table-2;
        }

        @media(--from-normal-screen-w) {
            margin-bottom: 0;

            background: $color-striped-table-1;
            box-shadow: none;

            &:nth-of-type(even) {
                background-color: $color-striped-table-2;
            }
        }


        // States
        &.is-active {
            padding-bottom: rem(20);

            @media(--to-medium-screen-w) {
                box-shadow: none;
            }

            @media(--from-medium-screen-w) {
                padding-bottom: rem(10);
            }

            @media(--from-normal-screen-w) {
                padding-bottom: 0;
            }


            // Elements
            .venue-capacities-grid-button svg {
                transform: translateY(-50%) rotate(45deg);
            }

            .venue-capacities-grid-cell {
                @media(--to-normal-screen-w) {
                    display: flex;
                }
            }

            .venue-capacities-grid-room-meta-mobile {
                @media(--to-normal-screen-w) {
                    display: block;
                }
            }
        }


        // Elements
        .venue-capacities-grid-text-cell-inner {
            min-height: rem(55);
            padding-top: rem(20);

            @media(--from-medium-screen-w) {
                min-height: rem(80);
                padding-top: rem(27);
                padding-bottom: rem(25);
            }

            @media(--from-normal-screen-w) {
                padding-top: rem(16);
                padding-bottom: rem(10);
            }
        }

        .venue-capacities-grid-cell {
            min-height: rem(50);
        }
    }

    &-room-meta-mobile {
        @media(--to-normal-screen-w) {
            display: none;
        }

        @media(--from-medium-screen-w) {
            flex-basis: 50%;
        }

        @media(--from-normal-screen-w) {
            display: none;
        }
    }

    &-room-meta-desktop {
        color: $color-dark-tint-1;
        font-family: $font-brandon;
        font-size: rem(14);

        @media(--to-normal-screen-w) {
            display: none;
        }
    }

    &-room-meta-desktop-value {
        font-weight: $weight-semi-bold;
    }

    &-venue {
        @media(--to-normal-screen-w) {
            display: none;
        }


        // Elements
        .venue-capacities-grid-text-cell-inner {
            min-height: rem(90);
            padding-top: rem(36);
            padding-right: rem(20);
            padding-bottom: rem(10);
            padding-left: rem(30);

            color: $color-dark;
            font-family: $font-primary;
            font-weight: $weight-semi-bold;
            text-align: left;
        }
    }
}
