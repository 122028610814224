/*
    Header
 */
.header {
    color: $color-light;

    background-color: $color-dark;


    // Elements
    &-inner {
        position: relative;
        z-index: $layer-header-main;

        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem($header-height);

        @media (--from-small-screen-w) {
            height: rem($header-height-large);
        }
    }

    &-close-button {
        position: relative;
        z-index: $layer-header-close-button;
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: rem($header-height);

        color: $color-light;

        @media (--from-small-screen-w) {
            height: rem($header-height-large);
        }

        @media (--from-nav-w) {
            display: none;
        }

        .action-button {
            height: rem(40);
            padding-left: rem(10);

            color: $color-light;
        }
    }

    &-side-actions {
        display: flex;
        justify-content: flex-end;

        @media (--to-nav-w) {
            flex-grow: 1;
        }

        @media (--from-nav-w) {
            order: 2;
            width: rem(60);
        }
    }

    &-notification-toggle {
        position: relative;

        width: rem(40);
        height: rem(40);

        color: $color-primary;

        border: none;

        &, &:hover {
            background: transparent;
        }


        // States
        &.is-active {
            &:before {
                opacity: 0;
            }
        }


        // Pseudo
        &:before {
            position: absolute;
            top: rem(11);
            right: rem(9);

            display: block;
            width: rem(8);
            height: rem(8);

            background-color: $color-light;
            border-radius: 50%;
            content: '';
            opacity: 1;

            transition: opacity 0.15s ease-in-out;
        }


        // Elements
        .icon {
            width: rem(22);
            height: rem(22);

            @media (--from-small-screen-w) {
                width: rem(25);
                height: rem(25);
            }
        }
    }
}
